'use strict';

module.exports = function() {

  function BaseObject() {
    this.x = 0; this.y = 0;
    this.w = 1; this.h = 1; // default width/height?
  }

  function Field() {
    BaseObject.call(this);
  }
  Field.prototype = Object.create(BaseObject.prototype);
  Field.prototype.constructor = Field;

};