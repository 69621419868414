'use strict';

module.exports = function($) {
  $(function() {
    
    $.scanBarcode = function() {
      return $.getScriptIf('/bower_components/quagga/dist/quagga.min.js')
      .then(function() {
        return $.modal('<div class="modal-header"><button class="close" type="button" data-dismiss="modal"><i class="fas fa-new-times" /></button><h3 class="modal-title">Scan Barcode</h3></div><div class="modal-body" id="scanning-stream" style="height: 95vh;"></div>', {
          is_html: true,
          size: 'modal-fill',
          shown: function($m) {
            window.Quagga.onDetected(function(e) {
              $m.data('result', e.codeResult.code);
              $m.modal('hide');
            });
            window.Quagga.init({
              inputStream: {
                name: 'Live',
                type: 'LiveStream',
                constraints: {
                  facingMode: ["environment", "rear", "back"]
                },
                target: $m.find('.modal-body').get(0),
                area: { // defines rectangle of the detection/localization area
                  top: "0%",    // top offset
                  right: "0%",  // right offset
                  left: "0%",   // left offset
                  bottom: "0%"  // bottom offset
                },
                locate: false
              },
              decoder: {
                readers: [
                  "code_128_reader",
                  "code_39_reader",
                  "code_39_vin_reader"
                ]
              }
            }, function(err) {
              if (err) {
                console.log(err);
                return;
              }
              window.Quagga.start();
            });
          },
          hidden: function() {
            window.Quagga.stop();
            window.Quagga.offDetected();
          }
        });
      });
    };

    $.fn.enableCameraBarcodes = function() {
      if (!navigator.mediaDevices || typeof navigator.mediaDevices.getUserMedia !== 'function') {
        console.log('Unable to enable camera barcode scanning - no camera access in this browser');
        this.addClass('hidden');
        return;
      }

      if (!this.data('barcode-scan')) {
        this.data('barcode-scan', true).click(function(e) {
          e.preventDefault();
          var $btn = $(this);
          var $input = $btn.parent().prev();
  
          $.scanBarcode().then(function(barcode) {
            if (!barcode) return;
            $input.val(barcode).change();
            if ($btn.hasClass('barcodeScannerBlur'))
              $input.blur();
            $input.trigger('snd:barcode-scanned');
          });
        });
      }

      return this;
    };

    $('.barcode-scanner input + .input-group-btn button').enableCameraBarcodes();
  });
};