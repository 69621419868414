'use strict';

var $ = window.jQuery;

function getInfoFromZip(zipcode, country) {
  return new Promise(function(resolve, reject) {
    var result = null;
    var components = '';
    if (country) components = '&components=country:' + country;
    $.getJSON('https://maps.googleapis.com/maps/api/geocode/json?address=' + zipcode + '&key=AIzaSyCD4O27Opwd_kp2YSuunLvqHqhkG0zqZXA' + components, function(response) {
      if (!response.results || !response.results[0] || !response.results[0].address_components) {
        reject(new Error('Response components not found'));
        return;
      }
      result = {};
      var components = response.results[0].address_components;
      $.each(components, function(index, component) {
        if (component.types.indexOf('locality') > -1)
          result.city = component.long_name;
        if (component.types.indexOf('country') > -1) {
          result.country = {
            abrv: component.short_name,
            name: component.long_name
          };
        }
        if (component.types.indexOf('administrative_area_level_1') > -1) {
          result.state = {
            abrv: component.short_name,
            name: component.long_name
          };
        }
        if (component.types.indexOf('administrative_area_level_2') > -1) {
          result.county = component.long_name;
        }
      });
      if (!result.city) {
        $.each(components, function(index, component) {
          if (component.types.indexOf('postal_town') > -1)
            result.city = component.long_name;
        });
      }
      resolve(result);
    });
  });
}

$.fn.enableZipLookup = function(countrySelector, citySelector, stateSelector, countySelector) {
  this.data('ziplookup', {
    countrySelector: countrySelector,
    citySelector: citySelector,
    stateSelector: stateSelector,
    countySelector: countySelector
  });
  this.blur(zipLookup);
  return this;
};

$.fn.disableZipLookup = function() {
  this.off('blur', zipLookup);
  return this;
};

function zipLookup(e) {
  var selectors = $(e.currentTarget).data('ziplookup');
  var zip = $(e.currentTarget).val();
  if (!zip || !zip.trim()) return;
  var country = (selectors.countrySelector) ? $(selectors.countrySelector).val() : null;
  getInfoFromZip(zip.trim(), country)
  .then(function(result) {
    if (result.country && selectors.countrySelector) $(selectors.countrySelector).val(result.country.abrv).change();
    if (result.county && selectors.countySelector) $(selectors.countySelector).val(result.county).trigger('input');
    if (result.city && selectors.citySelector) $(selectors.citySelector).val(result.city).trigger('input');
    if (result.state && selectors.stateSelector) {
      var $state = $(selectors.stateSelector);
      if ($state.prop('tagName') !== 'SELECT') {
        $state.val(result.state.name).trigger('input');
        return;
      }

      var abrvToCheck = result.state.abrv.toLocaleLowerCase(),
          nameToCheck = result.state.name.toLocaleLowerCase();

      var $options = $state.find('option');
      var $foundOption = null;
      $options.each(function() {
        var val = $(this).attr('value');
        var text = $(this).text();
        if (val) val = val.toLocaleLowerCase();
        if (text) text = text.toLocaleLowerCase();

        if (val === abrvToCheck || text === nameToCheck) {
          $foundOption = $(this);
          return false;
        }
      });
      if ($foundOption) {
        $state.val($foundOption.attr('value')).change();
      } else {
        console.log('State Not Found', result.state);
      }
    }
  })
  .catch(function(err) {
    console.log(err);
  });
}