'use strict';

module.exports = function($) {

  var clickLock = false;
  var clickAjax = [];

  $(document).ajaxSend(function(e, jqXHR) {
    if (clickLock) clickAjax.push(jqXHR);
  });

  $.fn.clickOnceLive = function(selector, fn) {
    if (arguments.length < 1) {
      this.trigger('clickOnce');
      return this;
    }

    if (typeof fn !== 'function') {
      throw new Error('Expected handler function');
    }

    var selected = this;
    var handler = function() {
      var $btn = $(this);
      $btn.attr('disabled', 'disabled');

      // call the original handler
      clickLock = true;
      var result = fn.apply(this, arguments);
      $.when(result).then(function() {}, function() {}).then(function() {
        // we don't care what the result was, or whether it failed - just that it completed
        clickLock = false;
        var tasksRunning = [{}];
        while (clickAjax.length) tasksRunning.push(clickAjax.shift());
        $.when.apply($, tasksRunning).then(function(){}, function(){}).then(function() {
          // again we don't care the results - just that they are all finished
          selected.one('click', selector, handler);
          $btn.removeAttr('disabled');
        });
      });
      return result;
    };
    return this.one('click', selector, handler);
  };

};