'use strict';

var $ = window.jQuery, _ = window._;

$.fn.enableVinExplosion = function() {
  return this.off('blur').blur(function() {
    var $vin = $(this),
        vin = $vin.val(),
        lastRan = $vin.data('vin-ran');
    
    var year = $('input[data-vin-field=29]').val();

    if (!vin || vin.length === 0 || vin === lastRan) {
      $vin.data('vin-ran', vin);
      return;
    }
    if (vin.length < 17) return $.handleFieldError('VIN must be 17 characters long', this);
    
    var updateVinField = false;
    if (vin.length > 17) {
      switch (vin.length) {
        case 18:
          vin = vin.substr(1); break;
        case 19:
          vin = vin.substr(1, 17); break;
        case 20:
          vin = vin.substr(2, 17); break;
      }
      if (vin.length === 17)
        updateVinField = vin;
    }

    if (updateVinField) {
      $vin.val(updateVinField);
    }
    
    var url = 'https://vpic.nhtsa.dot.gov/api/vehicles/decodevin/' + vin + '?format=json';
    if (year) url += '&modelyear=' + year;
    $.getJSON(url)
    .done(function(data) {
      if (!data || !data.Results || !data.Results.length)
        return $.handleFieldError('Unable to locate a vehicle with the specified VIN', $vin);
      
      var error = _.find(data.Results, function(r) { return r.VariableId === 143; });
      if (!error) return $.handleFieldError('Invalid result data for VIN', $vin);
      if (error.ValueId !== '0') {
        var errorMsg = error.Value;
        // further details _may_ be found in 156
        return $.handleFieldError(errorMsg, $vin);
      }

      _.each(data.Results, function(result) {
        $('input[data-vin-field=' + result.VariableId + ']').val(result.Value).trigger('input');
      });
    })
    .always(function() {
      $vin.data('vin-ran', vin);
    });
  });
};