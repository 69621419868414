const Luxon = require('luxon');

module.exports = {

  format: function(value, customFormat = null) {
    if (!value) return value;
    if (typeof value !== 'object' && typeof value !== 'string')
      return value;

    if (!Luxon.Duration.isDuration(value)) {
      let regexFormats = [
        /^(?:(\d+)\:(?=\d+\:\d+\:\d+\.?\d*))?(?:(\d+)\:(?=\d+\:\d+\.?\d*))?(?:(\d+)\:(?=\d+\.?\d*))?(\d+)$/,
        /^(?:(\d+(?:\.\d+)?)\s*[dD]\s*)?(?:(\d+(?:\.\d+)?)\s*[hH]\s*)?(?:(\d+(?:\.\d+)?)\s*[mM]\s*)?(?:(\d+(?:\.\d+)?)\s*[sS]\s*)?(?:(\d+(?:\.\d+)?)\s*(?:ms|MS|mS|Ms)\s*)?$/
      ];

      for (let r of regexFormats) {
        let result = value.match(r);
        if (result) {
          let arrResult = result.slice(1).map(s => {
            let i = parseInt(s);
            return Number.isNaN(i) ? 0 : i;
          });
          const [days, hours, minutes, seconds, milliseconds] = arrResult;
          let duration = Luxon.Duration.fromObject({
            days,
            hours,
            minutes,
            seconds,
            milliseconds
          });
          if (duration.isValid) {
            value = duration;
            break;
          }
        }
      }

      if (!Luxon.Duration.isDuration(value)) {
        let duration = Luxon.Duration.fromISO(value);
        if (!duration.isValid)
          return value;
        value = duration;
      }
    }

    let format = '';
    if (customFormat) {
      format = customFormat;
    } else {
      let formats = [];
      if (value.days) formats.push(`d'd'`);
      if (value.hours) formats.push(`h'h'`);
      if (value.minutes) formats.push(`m'm'`);
      formats.push(`s's'`);
      format = formats.join(' ');
    }
    return value.toFormat(format);
  }
};