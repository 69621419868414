'use strict';

function VirtualListItem(config, data) {
  this.position = config.position || 0;
  this.absPosition = config.absPosition || this.position;
  this.height = config.height || 0;
  this.data = data || {};
  this.containerId = config.containerId;
  this.index = config.index;
}

function VirtualList(config) {
  var width = (config && config.width ? config.width + 'px' : null) || '100%';
  var height = (this.height = (config && config.height ? config.height + "px" : null) || '100%');
  this.itemHeight = config.itemHeight;
  this.itemKey = config.itemKey;

  this.config = config;
  this.children = config.children;
  this.columns = config.columns;
  this.generatorFn = config.generatorFn;
  this.calculateHeight = config.calculateHeight;
  this.totalRows = (config.items && config.items.length) || 0;
  this.totalHeight = config.heightOffset || 0;
  this._sort = config.sort;
  
  this.items = this._buildItemArray(config.items);
  this.itemMap = this._buildItemMap(config.itemKey, this.items, config.children);

  this._calculatePositions();

  this.scroller = config.scroller || VirtualList.createScroller(this.totalHeight);
  this.container = config.container || VirtualList.createContainer(width, height);

  if (this.children && this.children.list instanceof VirtualList) {
    this.children.list.parentList = this;
  }

  this.disableTooltips = config.disableTooltips;

  this.first = 0;

  let self = this;
  this.lastRepaintY = 0;

  function onScroll(e) {
    let scrollTop = e.target.scrollTop;
    
    if (scrollTop < self.lastRepaintY) {
      let lastFirstVisible = self.first;
      for (let i = self.first; i >= 0; i--) {
        if (scrollTop > 20 && self.isItemHidden(self.items[i]))
          continue;
        if (scrollTop <= 20 || self.isItemVisible(self.items[i], scrollTop)) {
          lastFirstVisible = i;
        } else {
          break;
        }
      }
      self.first = lastFirstVisible;
    } else {
      for (let i = self.first; i < self.items.length; i++) {
        if (self.isItemVisible(self.items[i], scrollTop)) {
          self.first = i;
          break;
        }
      }
    }
    self.first = Math.max(self.first, 0);
    self._render(self.items, scrollTop);
    self.lastRepaintY = scrollTop;

    if (self.disableTooltips) {
      self.disableTooltips();
    }
    e.preventDefault();
  }

  if (!this.config.disableScroll) {
    this.container.addEventListener('scroll', onScroll);
  }
}

VirtualList.prototype.sort = function() {
  if (!this._sort) return;
  this.items.sort((a, b) => this._sort(a.data, b.data));
};

VirtualList.prototype.getIndicies = function(indicies) {
  return indicies.map(idx => {
    return this.items.findIndex(a => a.index === idx);
  });
};

VirtualList.prototype.isItemVisible = function(item, scrollTop) {
  if (this.isItemHidden(item)) return false;
  let finalHeight = scrollTop + this.container.offsetHeight;
  
  let itemTop = item.absPosition;
  let itemBot = itemTop + item.height;
  return (
    (itemTop >= scrollTop && itemTop <= finalHeight) ||
    (itemBot >= scrollTop && itemBot <= finalHeight)
  );
};

VirtualList.prototype.isItemHidden = function(item) {
  return (item.data && item.data.hidden);
};

VirtualList.prototype.getRenderedItems = function() {

};

VirtualList.prototype._renderChunk = function(items, itemUpdateIdx = null) {
  if (!items || !items.length) return;
  var fragment = document.createDocumentFragment();
  if (!this.parentList)
    fragment.appendChild(this.scroller);

  var nodeId = items[0].containerId;

  for (var i = 0; i < items.length; i++) {
    if (nodeId != items[i].containerId) {
      this._appendToNode(nodeId, fragment);
      nodeId = items[i].containerId;
    }
    var item;
    if (this.generatorFn) {
      let forceUpdate = false;
      if (Array.isArray(itemUpdateIdx)) {
        let indexFound = itemUpdateIdx.find(id => id == items[i].index);
        forceUpdate = (!!indexFound || indexFound === 0);
      } else {
        forceUpdate = (items[i].index == itemUpdateIdx);
      }
      item = this.generatorFn(i, items[i].data, this.columns, forceUpdate);
    } else {
      if (typeof this.items[i] === 'string') {
        var itemText = document.createTextNode(items[i]);
        item = document.createElement("div");
        item.style.height = this.height;
        item.appendChild(itemText);
      } else {
        item = items[i];
      }
    }
    
    if (this.config.containerHPadding) {
      item.style.width = `calc(100% - ${this.config.containerHPadding}px)`;
    } else {
      item.style.width = "100%";
    }
    item.style.position = "absolute";
    item.style.top = items[i].position + "px";
    item.style.height = items[i].height + "px";
    fragment.appendChild(item);
  }

  this._appendToNode(nodeId, fragment);
};

VirtualList.prototype._appendToNode = function(nodeId, fragment) {
  var node = document.getElementById(nodeId);
  if (!node) throw new Error('Missing Container');
  node.innerHTML = "";
  node.appendChild(fragment);
};


VirtualList.prototype._renderChildren = function(items, scrollTop) {
  let children = [], usedIndexes = [];
  let itemCount = items.length;
  for (let i = 0; i < itemCount; i++) {
    if (this.isItemVisible(items[i], scrollTop)) {
      usedIndexes.push(i);
      children.push(items[i]);
    }
  }
  
  if (this.config.overscanCount && usedIndexes.length) {
    let firstIdx = usedIndexes[0];
    let lastIdx = usedIndexes[usedIndexes.length - 1];
    
    let preCount = 0;
    while (firstIdx - 1 >= 0 && preCount < this.config.overscanCount) {
      children.unshift(items[--firstIdx]);
      preCount++;
    }

    let postCount = 0;
    while (lastIdx + 1 <= itemCount - 1 && postCount < this.config.overscanCount) {
      children.push(items[++lastIdx]);
      postCount++;
    }
  }
  return children;
};

VirtualList.prototype._render = function(items, scrollTop, itemUpdateIdx = null, childUpdateIdx = null) {
  let finalHeight = scrollTop + this.container.offsetHeight;
  let h = items[this.first] ? items[this.first].absPosition : 0;
  let i = this.first;
  let renderItems = [];
  let childRenders = [];

  while (h <= finalHeight && i < items.length) {
    if (!items[i].data.deleted && !items[i].data.hidden) {
      renderItems.push(items[i]);
      childRenders.push(this._renderChildren(items[i].children, scrollTop));
      h += items[i].height;
    }
    i++;
  }

  let childItems = [];
  for (let i = 0; i < childRenders.length; i++) {
    for (let j = 0; j < childRenders[i].length; j++) {
      childItems.push(childRenders[i][j]);
    }
  }

  this._renderChunk(renderItems, itemUpdateIdx); 
  if (childItems && childItems.length) {
    this.children.list._renderChunk(childItems, childUpdateIdx);
  }
};

VirtualList.prototype.render = function() {
  this._render(this.items, 0);
};

VirtualList.createContainer = function(w, h) {
  var c = document.createElement("div");
  c.style.width = w;
  c.style.height = h;
  c.style.overflow = "auto";
  c.style.position = "relative";
  c.style.padding = 0;
  c.style.border = "1px solid black";
  return c;
};

VirtualList.createScroller = function(h) {
  var scroller = document.createElement("div");
  scroller.style.opacity = 0;
  scroller.style.top = 0;
  scroller.style.left = 0;
  scroller.style.width = "1px";
  scroller.style.height = h + "px";
  return scroller;
};

VirtualList.prototype._buildItemArray = function(items) {
  var list = [];
  for (var i=0; i<items.length; i++) {
    var itemCfg = {
      position: 0,
      height: 1,
      containerId: this.config.containerId,
      index: i,
    };
    var item = new VirtualListItem(itemCfg, this.config.items[i]);
    item[this.itemKey] = this.config.items[i][this.itemKey];
    list.push(item);
  }
  return list;
};

VirtualList.prototype._calculatePositions = function() {
  this.sort();
  this.totalHeight = this.config.heightOffset || 0;
  for (let i = 0; i < this.items.length; i++) {
    var item = this.items[i];
    if (item.data.deleted || item.data.hidden) continue;
    item.position = this.totalHeight || 0;
    item.absPosition = item.position;
    item.height = this.calculateHeight ? this.calculateHeight(item.children) : this.itemHeight;
    this.totalHeight += item.height;
    if (i < this.items.length-1) {
      this.totalHeight += (this.config.gap || 0);
    }

    if (this.children && item.children) {
      let childHt = 0;
      for (let x = 0; x < item.children.length; x++) {
        let padding = this.config.children.padding
          ? this.config.children.padding(
            item.children[x].data,
            x > 0 ? item.children[x-1].data : null,
            x < item.children.length - 1 ? item.children[x+1].data : null
          )
          : null;
        item.children[x].position = childHt;
        if (padding && padding.top) {
          item.children[x].position += padding.top;
          childHt += padding.top;
        }
        childHt += item.children[x].height + this.children.list.config.gap;
        item.children[x].absPosition = item.position + (this.config.heightOffset || 0) + item.children[x].position;
        item.children[x].containerId = this.children.getChildContainerId(item.data);
      }
    }
  }
  if (this.scroller) {
    this.scroller.style.height = this.totalHeight + 'px';
  }
};

VirtualList.getChildren = function(child, parent, items) {
  var children = items.filter(itm => child.filter(parent.data, itm.data));
  if (child.sort) {
    children.sort(child.sort);
  }
  return children;
};

VirtualList.prototype._buildItemMap = function(key, items, children) {
  var map = {};
  for (var i=0; i<items.length; i++) {
    var item = items[i];
    if (children) {
      var child = children;
      if (child.list && child.filter) {
        item.children = VirtualList.getChildren(child, item, child.list.items);
      }
    }
    map[item.data[key]] = item;
  }
  return map;
};

VirtualList.prototype.getParentItem = function(idx) {
  let parentIdx = this.getParentItemIndex(idx);
  if (parentIdx < 0) return null;
  let parent = this.parentList;
  return parent.items[parentIdx];
};

VirtualList.prototype.getParentItemIndex = function(idx) {
  if (!this.parentList) return -1;

  let parent = this.parentList;
  let children = parent.children;
  
  if (children) {
    // find parent item that matches this one...
    let item = this.items[idx];
    return parent.items.findIndex(p => children.filter(p.data, item.data));
  }
  return -1;
};

VirtualList.prototype.updateItem = function(itemIdx, childItemIndex) {
  if (this.parentList) {
    let parent = this.parentList;
    let children = parent.children;
    let key = parent.itemKey;
    
    let madeChanges = false;
    if (children) {
      // find parent item that matches this one...
      let itemIndexes = Array.isArray(itemIdx) ? itemIdx : [itemIdx];
      let parentsToUpdate = {};
      for (let i of itemIndexes) {
        let item = this.items[i];
        let parentItem = parent.items.filter(p => children.filter(p.data, item.data));
        if (!parentItem || !parentItem.length) continue;

        if (!parentsToUpdate[parentItem[0].data[key]])
          parentsToUpdate[parentItem[0].data[key]] = parentItem[0];
      }

      for (let pKey of Object.keys(parentsToUpdate)) {
        madeChanges = true;
        let parentItem = parentsToUpdate[pKey];
        let mapItem = parent.itemMap[pKey];
        mapItem.children = VirtualList.getChildren(children, parentItem, children.list.items);
      }
    }
    
    if (madeChanges) {
      this.parentList._calculatePositions();
      this.parentList._render(
        this.parentList.items,
        this.parentList.lastRepaintY,
        null,
        itemIdx
      );
    } else {
      this.parentList._render(this.parentList.items, this.parentList.lastRepaintY);
    }
  } else {
    let children = this.children;
    let key = this.itemKey;
    let madeChanges = false;

    let itemIndexes = Array.isArray(itemIdx) ? itemIdx : [itemIdx];
    itemIndexes = this.getIndicies(itemIndexes);
    let itemsToUpdate = {};
    for (let i of itemIndexes) {
      let item = this.items[i];
      if (!itemsToUpdate[item.data[key]])
        itemsToUpdate[item.data[key]] = item;
    }

    if (children && childItemIndex) {
      itemIndexes = Array.isArray(childItemIndex) ? childItemIndex : [childItemIndex];
      for (let i of itemIndexes) {
        let item = children.list.items[i];
        let parentItem = this.items.filter(p => children.filter(p.data, item.data));
        if (!parentItem || !parentItem.length) continue;
        
        if (!itemsToUpdate[parentItem[0].data[key]])
          itemsToUpdate[parentItem[0].data[key]] = parentItem[0];
      }
    }

    for (let pKey of Object.keys(itemsToUpdate)) {
      madeChanges = true;
      let item = itemsToUpdate[pKey];
      let mapItem = this.itemMap[pKey];
      mapItem.children = VirtualList.getChildren(children, item, children.list.items);
      console.log('Calculate Children:', mapItem.id, mapItem.data.title, mapItem.children.length);
    }

    if (madeChanges) {
      console.log('  Recaculate Positions');
      this._calculatePositions();
      this._render(this.items, this.lastRepaintY, itemIdx, childItemIndex);
    } else {
      this._render(this.items, this.lastRepaintY);
    }
  }
};

VirtualList.prototype.refresh = function (){
  this._calculatePositions();
  this._render(this.items, this.lastRepaintY);
}

VirtualList.prototype.addItem = function(itemIdx) {
  var item = new VirtualListItem({
    position: 0,
    height: 1,
    containerId: this.config.containerId,
    index: itemIdx,
  }, this.config.items[itemIdx]);
  item[this.itemKey] = this.config.items[itemIdx][this.itemKey];

  this.items.push(item);
  
  if (this.config.children) {
    if (this.config.children.list && this.config.children.filter) {
      item.children = VirtualList.getChildren(this.config.children, item, this.config.children.list.items);
    }
  }
  this.itemMap[item[this.itemKey]] = item;
}

window.VirtualList = VirtualList;