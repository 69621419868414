'use strict';

var Moment = require('moment-timezone');

// Moment.createFake = function() {
//   return {
//     format: function() { return ''; },
//     isValid: function() { return false; }
//   };
// };

Moment.createFake = function createFake() {
  return {
    isFake: true,
    format: function() { return ''; },
    isValid: function() { return false; },
    toJSON: function() { return ''; },
    toString: function() { return ''; },
    clone: function() { return Moment.createFake(); }
  };
};

Moment.tz_old = function(...args) {
  if (args.length && typeof args[0] === 'string' && args[0].match(/^0017\-/))
    args[0] = args[0].replace(/^0017\-/, '1970-');
  if (args.length && typeof args[0] === 'string' && args[0].match(/(\-\d{2}\:\d{2})\:\d{2}$/))
    args[0] = args[0].replace(/(\-\d{2}\:\d{2})\:\d{2}$/, '$1');
  return Moment.tz.apply(Moment, args);
};

module.exports = Moment;