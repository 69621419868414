'use strict';

module.exports = {
  getTileWidth: function(lot_count) {
    if (lot_count < 2) return 100;
    if (lot_count <= 4) return 50;
    if (lot_count <= 9) return 100 / 3;
    if (lot_count <= 12) return 100 / 4;
    if (lot_count <= 15) return 100 / 5;
    if (lot_count <= 18) return 100 / 6;
    return 100 / 7;
  },
  getTileHeight: function(lot_count) {
    if (lot_count < 3) return 100;
    if (lot_count <= 6) return 50;
    if (lot_count <= 21) return 100 / 3;
    if (lot_count <= 28) return 100 / 4;
    if (lot_count <= 35) return 100 / 5;
    let p = Math.floor((lot_count - 35) / 7);
    return 100 / (6 + p);
  }
}