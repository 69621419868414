'use strict';

var $ = window.jQuery;

function Queue() {
  var promise = $.when();
  return function(cb) {
    promise = promise
      .then(null, function(err) {
        if (err) console.log(err);
        return $.when();
      })
      .then(cb);
    return promise;
  };
}

var theQueue = Queue();
$.fnQueue = function(fn, success, err) {
  switch(arguments.length) {
    case 1: return theQueue(fn);
    case 2: return theQueue(fn).then(success);
    case 3: return theQueue(fn).then(success, err);
    default:
      throw new Error('invalid number of arguments');
  }
};

var queues = {};
$.nmQueue = function(name, fn, success, err) {
  if (arguments.length < 1) throw new Error('Invalid number of arguments');
  if (!queues.hasOwnProperty(name)) 
    queues[name] = Queue();

  switch(arguments.length) {
    case 2: return queues[name](fn);
    case 3: return queues[name](fn).then(success);
    case 4: return queues[name](fn).then(success, err);
    default:
      throw new Error('invalid number of arguments');
  }
};