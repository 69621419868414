'use strict';

// NPM Libraries
require('bootstrap-sass');
window.Cookies = require('js-cookie');
window.moment = require('lib/moment');
window.toastr = require('toastr');
window.Bluebird = require('bluebird/js/browser/bluebird');
// require('eonasdan-bootstrap-datetimepicker/src/js/bootstrap-datetimepicker.js');
window.PhotoSwipe = require('photoswipe');
window.PhotoSwipeUI_Default = require('photoswipe/dist/photoswipe-ui-default');

import {DiffDOM} from "diff-dom";
window.DiffDom = DiffDOM;
/*
//- script(type='text/javascript', src='/bower_components/bootstrap/dist/js/bootstrap.min.js')
script(src='/bower_components/tinymce/tinymce.min.js')
//- script(src='/bower_components/js-cookie/src/js.cookie.js')
script(src='/bower_components/bluebird/js/browser/bluebird.min.js')
script(src='/bower_components/moment/min/moment.min.js')
script(src='/bower_components/moment-timezone/builds/moment-timezone-with-data.min.js')
script(src='/bower_components/lodash/dist/lodash.min.js')
//- script(src='/bower_components/toastr/toastr.min.js')
script(src='/bower_components/autobahnjs/autobahn.min.js')
script(src='/bower_components/eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min.js')
//- script(src='/bower_components/photoswipe/dist/photoswipe.min.js')
//- script(src='/bower_components/photoswipe/dist/photoswipe-ui-default.min.js')
script(type='text/javascript' src="/darkroomjs/fabric.js")
script(type='text/javascript' src="/darkroomjs/darkroom.js")
script(src='/bower_components/readmore-js/readmore.min.js')
script(src='/bower_components/select2/dist/js/select2.full.min.js')
//-script(src='/bower_components/jquery-inputreader/dist/script.min.js')
script(src='/bower_components/jquery-inputreader/src/script.js')
script(src='/bower_components/intl-tel-input/build/js/intlTelInput.min.js')
script(src='/js/sndtinygallery/editor_plugin.js')
script(src='/js/sndtinygallery/link_plugin.js')
*/

// JMA Customizations
require('./lib/prototypes');
require('./lib/utils');

let $ = window.jQuery;
let _ = window._;

require('./lib/barcode-scan')($);
require('./lib/bootstrap')($);
require('./lib/check_designer')($);
require('./lib/click_once')($);
require('./lib/column_chooser');
require('./lib/country_state')($, _);
require('./lib/crossbar')($, _);
require('./lib/datetimepicker');
window.Decimal = require('./lib/decimal');
require('./lib/dndPageScroll');
require('./lib/driverslicense');
require('./lib/dropzone');
require('./lib/forms');
require('./lib/helpers');
require('./lib/modals');
require('./lib/ograph');
require('./lib/override');
require('./lib/page_size');
require('./lib/phones');
require('./lib/queue');
require('./lib/select2');
require('./lib/selectOnFocus');
require('./lib/signature');
require('./lib/table_filter');
require('./lib/table_sort');
require('./lib/tables');
require('./lib/tinymce');
require('./lib/topaz');
require('./lib/vinexplosion');
require('./lib/wizard');
require('./lib/xlsx');
require('./lib/ziplookup');
require('./lib/task_watcher');
require('./lib/virtualList');