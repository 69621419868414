'use strict';

var $ = window.jQuery, _ = window._, Crossbar = window.Crossbar;

var watchForTasks = { form: null, tasks: [] };
var crossbarListener = false;


$.addTaskWatcher = function addTaskWatcher(task_id, success, error) {
  addCrossbarListener(success, error);

  if (_.isArray(task_id)) {
    watchForTasks.form = task_id;
  } else {
    watchForTasks.form = task_id;
  }

  checkWatchedTaskMatch(success, error);

};

function addCrossbarListener (success, error) {
  if (!crossbarListener) {
    Crossbar.on('taskCompleted', function(e, data) {
      watchForTasks.tasks.push(data);
      checkWatchedTaskMatch(success, error);
    });
    Crossbar.then(function() {
      Crossbar.tasks.completed();
    });
    crossbarListener = true;
    console.log(crossbarListener);
  }
}

function checkWatchedTaskMatch(success, error) {
  if (!watchForTasks.form || !watchForTasks.tasks.length) 
    return;

  if (_.isArray(watchForTasks.form)) {
    var tasks = _.remove(watchForTasks.tasks, function(t) { return watchForTasks.form.includes(t.id); });
    if (!tasks.length) return;

    _.map(tasks, function(task) {
      if (task.status == 3) {
        success(task);
      } else {
        error(task);
      }
      watchForTasks.form.splice(watchForTasks.form.indexOf(task.id), 1);
    });
  } else {
    var task = _.find(watchForTasks.tasks, function(t) { return t.id == watchForTasks.form; });
    if (!task) return;

    if (task.status == 3) {
      success(task);
    } else {
      error(task);
    }
    watchForTasks = { form: null, tasks: [] };
  }
}
