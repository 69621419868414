'use strict';

var $ = window.jQuery,
    _ = window._,
    toastr = window.toastr;

var submitLock = false;
var submitAjax = [];
$(document).ajaxSend(function(e, jqXHR) {
  if (submitLock) submitAjax.push(jqXHR);
});


$.supportsHtml5FormAttr = function() {
  let form = document.createElement('form');
  let input = document.createElement('input');
  let div = document.createElement('div');
  let id = 'formtest' + (new Date()).getTime();
  let bool = false;

  form.id = id;

  input.setAttribute('form', id);
  div.appendChild(form);
  div.appendChild(input);

  document.documentElement.appendChild(div);

  bool = form.elements && form.elements.length === 1 && input.form === form;
  div.parentNode.removeChild(div);
  return bool;
};

$.fn.submit = function(data, fn) {
  if (arguments.length < 1) {
    this.trigger('submit');
    return this;
  }

  if (typeof data === 'function') {
    var t = data;
    data = fn;
    fn = t;
  }
  
  var selected = this;
  var handler = function() {
    selected.clearErrors().find('.submit-button, button[type=submit], input[type=submit]').attr('disabled', 'disabled');

    // call the original handler
    submitLock = true;
    var result = fn.apply(this, arguments);
    $.when(result).then(function() {}, function() {}).then(function() {
      // we don't care what the result was, or whether it failed - just that it completed
      submitLock = false;
      var tasksRunning = [{}];
      while(submitAjax.length) tasksRunning.push(submitAjax.shift());
      $.when.apply($, tasksRunning).then(function(){}, function(){}).then(function() {
        // again we don't care the results - just that they are all finished
        selected.one('submit', null, data, handler);
        selected.find('.submit-button, button[type=submit], input[type=submit]').removeAttr('disabled');
      });
    });
    return result;
  };
  return this.one('submit', null, data, handler);
};

$.fn.submitLive = function(selector, fn) {
  if (arguments.length < 1) {
    this.trigger('submit');
    return this;
  }

  if (typeof fn !== 'function') {
    throw new Error('Expected handler function');
  }
  
  var selected = this;
  var handler = function(e) {
    selected.find(e.target).clearErrors().find('.submit-button, button[type=submit], input[type=submit]').attr('disabled', 'disabled');

    // call the original handler
    submitLock = true;
    var result = fn.apply(this, arguments);
    $.when(result).then(function() {}, function() {}).then(function() {
      // we don't care what the result was, or whether it failed - just that it completed
      submitLock = false;
      var tasksRunning = [{}];
      while(submitAjax.length) tasksRunning.push(submitAjax.shift());
      $.when.apply($, tasksRunning).then(function(){}, function(){}).then(function() {
        // again we don't care the results - just that they are all finished
        selected.one('submit', selector, handler);
        selected.find('.submit-button, button[type=submit], input[type=submit]').removeAttr('disabled');
      });
    });
    return result;
  };
  return this.one('submit', selector, handler);
};

var rCRLF = /\r?\n/g,
    rsubmitterTypes = /^(?:submit|button|image|reset|file)$/i,
    rsubmittable = /^(?:input|select|textarea|keygen)/i;
    
function addDataToObject(obj, key, data) {
  if (Array.isArray(obj[key]))
    obj[key].push(data);
  else if (typeof obj[key] === 'undefined')
    obj[key] = data;
  else {
    obj[key] = [obj[key]];
    obj[key].push(data);
  }
}

const AJAX_DATA_OPTION_DEFAULTS = {
  include_plaintext: false,
};

$.fn.ajaxData = function(tinyMCE, useObjects, options) {
  options = _.defaults(options || {}, AJAX_DATA_OPTION_DEFAULTS);

  if (document && document.activeElement && this[0]) {
    if (this[0].contains(document.activeElement)) {
      if (typeof document.activeElement.blur === 'function') {
        document.activeElement.blur();
      }
    }
  }

  var selfForm = this;
  var elements = this.map(function() {
    var elements = $.prop(this, "elements");
    return elements ? $.makeArray(elements) : this;
  })
  .filter(function() {
    var type = this.type;
    return this.name && !$(this).is(':disabled') && 
      rsubmittable.test(this.nodeName) && !rsubmitterTypes.test(type);
  });

  var data = {};
  elements.each(function(i, elem) {
    var val = $(elem).val();

    if (/^(?:checkbox)$/i.test(elem.type)) {
      if (!$(elem).hasClass('submit-value'))
        val = elem.checked ? 'true' : 'false';
      else if (!elem.checked)
        return;
    }

    if (/^(?:radio)$/i.test(elem.type) && !elem.checked)
      return;

    if (val == null) return;

    if (Array.isArray(val)) {
      $.each(val, function(i, val) {
        addDataToObject(data, elem.name, val.replace(rCRLF, "\r\n"));
      });
      return;
    }
    addDataToObject(data, elem.name, val.replace(rCRLF, "\r\n"));
  });

  if (tinyMCE && tinyMCE.editors) {
    _.each(tinyMCE.editors, function(e) {
      var frmElement = selfForm.find('#' + e.id);
      if (frmElement.length) {
        data[frmElement.attr('name')] = e.getContent();
        if (options.include_plaintext) {
          data[frmElement.attr('name') + '_plain'] = e.getContent({ format: 'text' });
        }
      }
    });
  }

  var formattedData = {};
  _.forOwn(data, function(value, key) {
    if (useObjects) _.setWith(formattedData, key, value, Object);
    else _.set(formattedData, key, value);
  });

  this.trigger('snd:extraFormData', [formattedData]);
  return formattedData;
};

$.fn.ajaxData_old = function(tinyMCE, useObjects) {
  var data = {}, selfForm = this;
  $.each(this.serializeArray(), function(i, field) {
    var $chk = selfForm.find('input[type=checkbox][name="' + field.name + '"]:not(.submit-value)');
    if ($chk.length) {
      field.value = $chk.is(':checked') ? 'true' : 'false';
    }
    if (typeof data[field.name] === 'undefined') {
      if (useObjects) _.setWith(data, field.name, field.value, Object);
      else _.set(data, field.name, field.value);
    } else {
      var current = _.get(data, field.name);
      if (!current.push || typeof current.push !== 'function') {
        current = [current];
        if (useObjects) _.setWith(data, field.name, current, Object);
        else _.set(data, field.name, current);
      }
      current.push(field.value);
    }
  });

  this.find('input[type=checkbox]:not(.submit-value):not(:checked)').each(function() {
    if (!this.name || typeof data[this.name] !== 'undefined') return;
    if (useObjects) _.setWith(data, this.name, 'false', Object);
    else _.set(data, this.name, 'false');
  });

  this.find('select[multiple]').each(function() {
    if (useObjects) _.setWith(data, this.name, $(this).val(), Object);
    else _.set(data, this.name, $(this).val());
  });

  if (tinyMCE && tinyMCE.editors) {
    _.each(tinyMCE.editors, function(e) {
      var frmElement = selfForm.find('#' + e.id);
      if (frmElement.length) {
        data[frmElement.attr('name')] = e.getContent();
      }
    });
  }
  
  this.trigger('snd:extraFormData', [data]);
  return data;
};

$.ajaxPrefilter(function(opts, originalOpts, jqXHR) {
  if (!opts.autoOverrideModal) {
    return;
  }
  
  let dfd = $.Deferred();
  jqXHR.done(dfd.resolve);
  jqXHR.fail(function() {
    let args = Array.prototype.slice.call(arguments);
    if (jqXHR && jqXHR.responseJSON && jqXHR.responseJSON.needs_override) {
      $.handleFormErrors()(...args);
      $.modal('/override/modal/' + (jqXHR.responseJSON.override_action || '')).then(function(result) {
        if (!result) {
          dfd.rejectWith(jqXHR, [{skip_form_errors: true}, jqXHR.status, args[2]]);
          return;
        }
        // _.extend(data, result);
        // data.override = result.code;
        let newOpts = $.extend({}, originalOpts);
        if (newOpts.data) {
          if (newOpts.contentType === 'application/json' && typeof newOpts.data === 'string') {
            newOpts.data = JSON.parse(newOpts.data);
            _.extend(newOpts.data, result);
            newOpts.data.override = result.code;
            newOpts.data = JSON.stringify(newOpts.data);
          } else {
            _.extend(newOpts.data, result);
            newOpts.data.override = result.code;
          }
        }
        // remove option so infinite override calls don't happen
        delete newOpts.autoOverrideModal;

        $.ajax(newOpts).done(dfd.resolve, dfd.reject);
      });
    } else {
      dfd.rejectWith(jqXHR, args);
    }
  });

  return dfd.promise(jqXHR);
});

$.fn.clearErrors = function() {
  this.find('.hasError').removeClass('hasError').off('mousemove', errorTooltipPosition);
  this.find('.form-group.has-error, .form-group-sm.has-error').removeClass('has-error').off('mousemove', errorTooltipPosition);
  this.find('select.select2:not(.hasError)  + .select2-container .select2-selection__rendered').each(function() {
    var tmpTitle = $(this).data('snd:tmp-err-title');
    if (tmpTitle && !$(this).closest('.has-error').length) {
      $(this).data('snd:tmp-err-title', null);
      $(this).attr('title', tmpTitle);
    }
  });
  return this;
};

$.handleSyncErrors = function(errors) {
  // seperate sync errors
  let syncErrors = [];
  let otherErrors = [];
  errors.forEach(err => {
    if(err.extra && err.extra.status === 'syncError') {
      syncErrors.push(err);
    } else {
      otherErrors.push(err)
    }
  });

  syncErrors.forEach(err => { // toast sync errors
    toastr.error(err.message);
  });

  return otherErrors
}

$.handleFormErrors = function(selector, callback) {
  if (typeof selector === 'function') {
    callback = selector;
    selector = null;
  }

  if (typeof callback !== 'function') callback = null;

  return function(result, status, xhr) {
    if (!result.skip_form_errors) {
      if (result.status === 0) {
        toastr.error("<strong>Connection Issue</strong>: There is an issue connecting to the server right now");
      } else {
        if (window.util.IsJSON(result.responseText)) {
          if (JSON.parse(result.responseText).errors != null) {
            result.response = JSON.parse(result.responseText);
            if (result.response.errors) {
              console.log(result.response.errors);
              result.response.errors = $.handleSyncErrors(result.response.errors);
              _.each(result.response.errors, function(err){
                var message = err.message;
                var canToast = true;
                if (err.extra) {
                  if (err.extra.redirect) message = message += '<br><a href=' + err.extra.redirect.link + '><strong>' + err.extra.redirect.label + '</strong></a>';
                  if (err.extra.modal) {
                    canToast = false;
                    var title = err.extra.modal.title ? err.extra.modal.title : null;
                    if (err.extra.modal.redirect) {
                      $.choice(title, err.message, [
                        'OK',
                        {url: err.extra.modal.redirect.link, display: err.extra.modal.redirect.label}
                      ])
                      .then(function(choice) {
                        if (choice === 'OK') return;
                        if (choice.url) {
                          window.location.href = choice.url;
                        }
                      });
                    } else {
                      $.msgbox(title, err.message);
                    }
                  }
                }
                if (canToast) toastr.error(message);
                handleFieldError(err, selector);
              });
            }
            if (result.response.message) {
              toastr.error(result.response.message);
            }
          }
        } else {
          toastr.error(result.responseText);
        }
      }
    }
    if (callback) return callback.call($(selector), result, status, xhr);
  };
};

function handleFieldError(err, selector) {
  if (typeof err === 'string') {
    err = {
      param: (typeof selector === 'object' ? '#'+$(selector).attr('id') : 'snd-bogus-selector'),
      message: err
    };
    selector = null;
  }
  
  var fldSelector = /^[\#\. \[\]]/.test(err.param) ? err.param : '[name="' + err.param + '"]';
  var fld = selector ? $(selector).find(fldSelector) : $(fldSelector);
  
  if (fld.length) {
    var fldGroup = fld.closestWithin('.form-group', 3);
    if (!fldGroup.length) fldGroup = fld.closestWithin('.form-group-sm', 3);
    // var tooltipOptions = {
    //   placement: 'auto top',
    //   trigger: 'hover',
    //   title: err.message,
    //   template: `<div class="tooltip has-error" role="tooltip">
    //     <div class="tooltip-arrow"></div>
    //     <div class="tooltip-inner"></div>
    //   </div>`
    // };
    if (fldGroup.length) {
      fldGroup.addClass('has-error');
      // fldGroup.tooltip(tooltipOptions);
      fldGroup.data('err-msg', err.message);
      fldGroup.on('mousemove', errorTooltipPosition);
    } else {
      fld.addClass('hasError');
      // fld.tooltip(tooltipOptions);
      fld.data('err-msg', err.message);
      fld.on('mousemove', errorTooltipPosition);
    }
  }
  
  if (!fld.length && selector) {
    $(selector).trigger('snd:extraFormErrors', [err]);
  }
}
$.handleFieldError = function(err, selector) {
  return handleFieldError(err, selector);
};

function errorTooltipPosition(e) {
  var tooltipID = $(e.currentTarget).attr('aria-describedby');
  var $tooltip = $('#' + tooltipID);
  if (!$tooltip.length) return;

  var actualWidth = $tooltip[0].offsetWidth;
  //var newLeft = e.pageX - $(this.offsetParent).offset().left - (actualWidth / 2);
  var newLeft = e.pageX - (actualWidth / 2);
  newLeft = Math.round(newLeft);
  $tooltip.css({
    left: newLeft + 'px',
    'z-index': 999999
  });
}

$.hiddenSubmit = function(frm, data) {
  var form = document.createElement('form');
  form.action = frm.url;
  form.method = frm.method || 'POST';
  form.target = frm.target || '_self';
  if (data) {
    for (var key in data) {
      if (!data.hasOwnProperty(key)) continue;
      var input = document.createElement('textarea');
      input.name = key;
      input.value = (typeof data[key] === 'object') ? ':json:' + JSON.stringify(data[key]) : data[key];
      form.appendChild(input);
    }
  }
  form.style.display = 'none';
  document.body.appendChild(form);
  form.submit();
};

$.fn.syncInput = function(sourceSelector) {
  var source = $(sourceSelector);
  if (this.is('input')) {
    var inputType = this.attr('type');
    if (typeof inputType === typeof undefined) inputType = 'text';
    switch(inputType) {
      case 'text':
        source.off('change').data('sync-target', this).on('change', syncInput).change();
        source.off('blur').data('sync-target', this).on('blur', syncInput);          
        break;
    }
    this.prop('readonly', true);
  } else {
    source.off('change').data('sync-target', this).on('change', syncInput).change();

    var hidden = $('<input type="hidden" />').attr('name', this.attr('name')).val(this.val());
    this.prop('disabled', true);
    this.after(hidden);
  }
  this.data('sync-source', sourceSelector);
  return this;
};

$.fn.disableSyncInput = function() {
  var sourceSelector = this.data('sync-source');
  if (sourceSelector) {
    if (this.is('input')) {
      var inputType = this.attr('type');
      if (typeof inputType === typeof undefined) inputType = 'text';
      switch(inputType) {
        case 'text':
          $(sourceSelector).off('keyup');
          break;
      }
    } else {
      $(sourceSelector).off('change');
      
      var next = this.next();
      if (next && next.attr('type') === 'hidden' && next.attr('name') === this.attr('name')) {
        next.remove();
      }
      this.prop('disabled', false);
    }
  }
  this.prop('readonly', false);
  return this;
};

function syncInput(e) {
  var target = $(e.currentTarget).data('sync-target');
  if (target) {
    $(target).val($(e.currentTarget).val());
    var next = $(target).next();
    if (next && next.attr('type') === 'hidden' && next.attr('name') === $(target).attr('name')) {
      next.val($(e.currentTarget).val());
    }
  }
}

function ctrlSaveListener($frm) {
  return function(e) {
    if (e.which === 83 && (e.ctrlKey || e.metaKey) && !e.altKey) {
      var modal = $.topModal();
      if (modal) {
        var frm = $(modal).find('form');
        if (frm && frm.length === 1) {
          e.preventDefault();
          frm.submit();
        }
      } else {
        e.preventDefault();
        $frm.submit();
      }
    }
  };
}

$.fn.ctrlSave = function(command) {
  var fn = null;
  if (command === 'destroy') {
    fn = this.data('ctrlSave_fn');
    if (fn) {
      $(document).off('keydown', fn);
    }
    return this;
  }
  fn = ctrlSaveListener(this);
  $(document).on('keydown', fn);
  this.data('ctrlSave_fn', fn);
  return this;
};

$.fn.numericOnly = function(selector) {
  if (selector) {
    this.on('keydown', selector, numericOnlyHandler);
  } else {
    this.on('keydown', numericOnlyHandler);
  }
};

var numericKeyCodes = _.concat(_.range(48, 58), _.range(96, 106), 8, 9, 13, 37, 39, 46, 110, 190);
function numericOnlyHandler(e) {
  if (e.shiftKey) e.preventDefault();
  if (!_.includes(numericKeyCodes, e.keyCode)) e.preventDefault();
  if ($(e.currentTarget).val().indexOf('.') !== -1 && (e.keyCode === 190 || e.keyCode === 110))
    e.preventDefault();
}

$.fn.inputGroupType = function(percentOrDollar, useFontAwesome) {
  this.each(function() {
    var $this = $(this);
    var $grp = $this.parent();
    if (!$grp.hasClass('input-group')) return;
    
    var $addon = $grp.find('.input-group-addon');
    if (percentOrDollar === '%') {
      if ($grp.children().last().hasClass('input-group-addon')) return;
      $addon.remove();
      if (useFontAwesome) {
        $grp.append('<span class="input-group-addon"><i class="far fa-new-percent" /></span>');
      } else {
        $grp.append('<span class="input-group-addon">%</span>');
      }
      $grp.addClass('align-percent');
      $grp.removeClass('align-dollar');
    } else if (percentOrDollar === '$') {
      if ($grp.children().first().hasClass('input-group-addon')) return;
      $addon.remove();
      if (useFontAwesome) {
        $grp.prepend('<span class="input-group-addon"><i class="far fa-new-dollar-sign" /></span>');
      } else {
        $grp.prepend('<span class="input-group-addon">$</span>');
      }
      $grp.addClass('align-dollar');
      $grp.removeClass('align-percent');
    }
  });
};

$(function() {
  $('input:alltext:not(.normalcase)').applyUpperCase();
  
  $('body').tooltip({
    selector: '.has-error, .hasError',
    container: 'body',
    placement: 'auto top',
    trigger: 'hover',
    title: function() { return $(this).data('err-msg'); },
    template: '<div class="tooltip has-error" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
  });
});