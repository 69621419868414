'use strict';

var $ = window.jQuery;

function fullyDisableDropzone(element) {
  element.dropzone.disable();
  var $dzp = $(element).find('.dropzone-previews');
  if ($dzp.sortable && element.dropzone.options.config.sortable)
    $dzp.sortable('disable');
}

function fullyEnableDropzone(element) {
  element.dropzone.enable();
  var $dzp = $(element).find('.dropzone-previews');
  if ($dzp.sortable && element.dropzone.options.config.sortable)
    $dzp.sortable('enable');
}

$.fn.disableDropzone = function() {
  if (this.hasClass('disabled')) return this;
  fullyDisableDropzone(this.get(0));
  this.addClass('disabled');
  return this;
};

$.fn.enableDropzone = function() {
  if (!this.hasClass('disabled')) return this;
  fullyEnableDropzone(this.get(0));
  this.removeClass('disabled');
  return this;
};

$(function() {
  $('.dropzone.disabled').each(function() {
    fullyDisableDropzone(this);
  });
});