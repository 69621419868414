'use strict';

var $ = window.jQuery;

$(function() {
  $('input.pageSize').change(function() {
    var query = window.util.getQuery();
    query.pageSize = this.value;
    if (query.pageSize == 50)
      delete query.pageSize;

    var evt = {path: window.location.pathname, handled: false};
    $('.search_row').trigger('snd:column-filter-path', [evt]);

    if (!evt.handled) {
      evt.path = evt.path.replace(/\/\d+$/g, '');
    }
    
    window.location = window.util.buildHref(evt.path, query);
  });
});