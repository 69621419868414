'use strict';
/* globals $, moment, Calendar */

// require('eonasdan-bootstrap-datetimepicker');

$.fn.dtp = function(options) {
  options = options || {};

  this.each(function(idx, element) {
    var $element = $(element);
    var $target = $element;
    var $input = $element;
    var value = null;
    if ($element.prop('tagName') === 'INPUT' && $element.hasClass('form-control') && $element.parent().hasClass('input-group')) {
      $target = $element.parent();
      value = $input.val();
    } else if ($element.hasClass('input-group') && $element.find('input.form-control').length) {
      $input = $element.find('input.form-control');
      value = $input.val();
    }
    var dataFormat = $element.data('date-format');
    if (!options.format && dataFormat) options.format = dataFormat;

    delete options.defaultDate;
    if (value) {
      value = options.format ? moment(value, options.format) : moment(value);
      if (value.isValid())
        options.defaultDate = value;
    }

    var modalBody = $target.closest('.modal-body');
    if (modalBody.length) {
      options.widgetParent = 'body';
      $target.addClass('is-dtp-datepicker');
    }
    $target.addClass('date');
    if (!options.hasOwnProperty('sideBySide'))
      options.sideBySide = true;
    $target.datetimepicker(options);
  });
};

$.fn.calendarRange = function(linkedElement) {
  var now = moment();
  var c = new Calendar({
    element: this,
    format: {input: 'M/D/Y'},
    earliest_date: moment.utc('0001-01-01'),
    start_date: null,
    end_date: null,
    same_day_range: true,
    presets: [
      {
        label: 'Today',
        start: now,
        end: now
      },
      {
        label: 'Last 30 days',
        start: moment(now).subtract(29, 'days'),
        end: now
      },
      {
        label: 'Last month',
        start: moment(now).subtract(1, 'month').startOf('month'),
        end: moment(now).subtract(1, 'month').endOf('month')
      },
      {
        label: 'Last 3 months',
        start: moment(now).subtract(3, 'month').startOf('month'),
        end: now,
      },
      {
        label: 'Last 6 months',
        start: moment(now).subtract(6, 'month').startOf('month'),
        end: now,
      },
      {
        label: 'Last year',
        start: moment(now).subtract(12, 'month').startOf('month'),
        end: now,
      },
      {
        label: 'All time',
        start: moment.utc('0001-01-01'),
        end: now
      }
    ],
    callback: function() {
      var start = moment(this.start_date).format('Y-MM-DD'),
            end = moment(this.end_date).format('Y-MM-DD');
      
      if (this.start_date.isSame(moment.utc('0001-01-01'), 'day')) {
        start = '';
        this.start_date = now;
        this.current_date = now;
        $('.dr-date-start', this.element).html(now.format(this.format.input));
      }
      if (start === '' && this.end_date.isSame(now, 'day'))
        end = '';
      
      if (start === '' && end === '')
        $(linkedElement).val('').change();
      else
        $(linkedElement).val(start + '/' + end).change();
    }
  });
  this.data('calendar-ref', c);
  var self = this;
  $(linkedElement).change(function() {
    var value = $(this).val();
    var parts = value.split('/', 2);
    while(parts.length < 2) parts.push('');

    var startEmpty = (parts[0].trim() === '');
    var endEmpty = (parts[1].trim() === '');
    var cal = null;

    if (!startEmpty) {
      cal = self.data('calendar-ref');
      var start = moment(parts[0]);
      if (!cal.start_date.isSame(start, 'day')) {
        cal.start_date = start;
        cal.current_date = start;
        $('.dr-date-start', cal.element).html(start.format(cal.format.input));
      }
      self.removeAttr('data-rangestartempty');
    } else {
      self.attr('data-rangestartempty', 'true');
    }

    if (!endEmpty) {
      cal = self.data('calendar-ref');
      var end = moment(parts[1]);
      if (!cal.end_date.isSame(end, 'day')) {
        cal.end_date = end;
        $('.dr-date-end', cal.element).html(end.format(cal.format.input));
      }
      self.removeAttr('data-rangeendempty');
    } else {
      self.attr('data-rangeendempty', 'true');
    }
  }).change();
};

$.fn.enableDateRange = function() {
  var self = this;
  $.getScriptIf('/bower_components/BaremetricsCalendar/public/js/Calendar.js')
  .then(function() {
    $.getStyleIf('/bower_components/BaremetricsCalendar/public/css/application.css');
    self.each(function() {
      var $this = $(this);
      var name = $(this).attr('name');
      var id = 'dtRange_' + name;

      $this.data('range-linked-editor', id)
          .attr('type', 'hidden')
          .after('<div id="' + id + '" class="daterange daterange-double" />');
      $('#' + id).calendarRange($this);
    });
  });
};

$(function() {
  $('input.daterange').enableDateRange();

  $(document).on('dp.show', '.modal-body .is-dtp-datepicker', function() {
    var $this = $(this);
    var $datepicker = $('body').find('.bootstrap-datetimepicker-widget:last');

    var top, left = $this.offset().left;
    if ($datepicker.hasClass('bottom')) {
      top = $this.offset().top + $this.outerHeight();
    } else if ($datepicker.hasClass('top')) {
      top = $this.offset().top - $datepicker.outerHeight();
    }

    $datepicker.css('z-index', 2147483647);
    $datepicker.css({
      top: top + 'px',
      bottom: 'auto',
      left: left + 'px'
    });
  });
});