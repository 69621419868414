'use strict';
/* globals tinyMCE */

var $ = window.jQuery,
    _ = window._,
    Promise = window.Promise,
    toastr = window.toastr,
    openModals = [];

$.topModal = function() {
  if (openModals.length) {
    var modal = openModals.pop();
    openModals.push(modal);
    return modal;
  }
  return null;
};

/* Yes, it breaks standard jQuery chaining */
$.fn.modalDialog = function(options) {
  //$importModal.modal({backdrop: 'static', keyboard: false, show: false});
  /*
  $M.data('result', false)
        .removeClass()
        .addClass('modal fade')
        .addClass(options.class)
        .find('.modal-content').html(url).end()
        .one('hidden.bs.modal', function() {
          var result = $M.data('result');
          setTimeout(function() { $M.remove(); }, 500);
          resolve(result);
        })
        .one('shown.bs.modal', function() {
          if (typeof options.shown === 'function')
            options.shown($M);
        })
        .removeClass('fade')
        .modal('show');
  */
  options = options || {};
  var $modal = this;
  return new Promise(function(resolve) {
    $modal.data('result', false)
    .one('hidden.bs.modal', function() {
      var result = $modal.data('result');
      resolve(result);
    })
    .one('shown.bs.modal', function() {
      if (typeof options.shown === 'function')
        options.shown($modal);
    })
    .modal({backdrop: 'static', keyboard: false, show: true});
  });
};

$.modal = function(url, options) {
  if (arguments.length === 0) {
    return $('[data-modal=remote-modal]').last();
  }

  options = _.defaults(options || {}, { id: window.util.generateUUID() });
  var $M = $('[data-modal=remote-modal]#' + options.id);
  if (!$M.length) {
    $M = $('<div id="'+options.id+'" data-modal="remote-modal"><div class="modal-dialog"><div class="modal-content"/></div></div>')
      .css('display', 'none')
      .appendTo('body')
      .modal({ show: false });
  }

  $M.find('.modal-dialog').removeClass('modal-fill modal-lg modal-sm');
  if (options.size) {
    $M.find('.modal-dialog').addClass(options.size);
  }

  var method = 'GET';
  if (options.method) method = options.method;

  var sendRequest = { method: method, url: url };

  if (options.data) sendRequest.data = options.data;
  if (options.contentType) sendRequest.contentType = options.contentType;

  var command = options.preventClose ? {backdrop: 'static', keyboard: false, show: true} : 'show';
  if (options.is_html) {
    return new Promise(function(resolve) {
      $M.data('result', false).data('options', options)
        .removeClass()
        .addClass('modal fade')
        .addClass(options.class)
        .find('.modal-content').html(url).end()
        .one('hidden.bs.modal', function() {
          if (typeof options.hidden === 'function')
            options.hidden($M);
          var result = $M.data('result');
          setTimeout(function() {
            $M.remove();
            resolve(result);
          }, 50);
        })
        .one('shown.bs.modal', function() {
          if (typeof options.shown === 'function')
            options.shown($M);
        })
        .one('show.bs.modal', function() {
          if (typeof options.show === 'function')
            options.show($M);
        })
        .removeClass('fade')
        .modal(command);
      if (options.preventClose)
        $M.data('bs.modal').options.backdrop = 'static';
    });
  }

  return new Promise(function(resolve) {
    $.ajax(sendRequest).done(function(html) {
      if (window.util.IsJSON(html)) {
        var result = JSON.parse(html);
        resolve(result.hasOwnProperty('result') ? result.result : null);
        return;
      }
      $M.data('result', false).data('options', options)
        .removeClass()
        .addClass('modal fade')
        .addClass(options.class)
        .find('.modal-content').html(html).end()
        .one('hidden.bs.modal', function() {
          if (typeof options.hidden === 'function')
            options.hidden($M);
          var result = $M.data('result');
          setTimeout(function() {
            $M.remove();
            resolve(result);
          }, 50);
        })
        .one('shown.bs.modal', function() {
          if (typeof options.shown === 'function')
            options.shown($M);
        })
        .one('show.bs.modal', function() {
          if (typeof options.show === 'function')
            options.show($M);
        })
        .removeClass('fade')
        .modal(command);
      if (options.preventClose)
        $M.data('bs.modal').options.backdrop = 'static';
    }).fail(function(result){
      if(window.util.IsJSON(result.responseText) && JSON.parse(result.responseText).errors != null){
        result.response = JSON.parse(result.responseText);
        _.each(result.response.errors, function(err){
          toastr.warning(err.message);
          var fld = $('[name=' + err.param + ']');
          if (fld.length) {
            var fldGroup = fld.closestWithin('.form-group', 3);
            if (fldGroup.length) {
              fldGroup.addClass('has-error');
            } else {
              fld.addClass('hasError');
            }
          }
        });
        toastr.error(result.response.message);
      }else{
        toastr.error(result.responseText);
      }
      resolve(false);
    });
  });
};

$.ask = function(title, msg, type) {
  if (arguments.length === 1) {
    type = title;
    title = null;
  }
  if (arguments.length === 2) {
    type = msg;
    msg = title;
    title = null;
  }
  if (!type) type = 'text';

  var id = window.util.generateUUID();
  var $m = $('<div id="' + id + '" class="modal fade"><div class="modal-dialog"><div class="modal-content"><div class="modal-header"></div><div class="modal-body"></div><div class="modal-footer"><button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button><button type="button" class="btn btn-primary" data-dismiss="modal">OK</button></div></div></div></div>');
  var $body = $m.find('.modal-body').html(msg);
  switch (type) {
    case 'textarea':
      $body.append('<textarea class="form-control modal-input"></textarea>');
      break;
    default: // text
      $body.append('<input type="text" class="form-control normalcase modal-input" />');
  }

  return new Promise(function(resolve) {
    $m.data('result', false)
      .on('click', '.btn', function(e) {
        if ($(e.target).is('.btn-primary')) {
          var value = $m.find('.modal-input').val();
          $m.data('result', value);
        }
      })
      .css('display', 'none')
      .appendTo('body')
      .toggleClass('no-title', !title)
      .find('.modal-header').html(title).toggle(!!title).end()
      .one('hidden.bs.modal', function() { resolve($m.data('result')); })
      .modal('show');
  });
};

$.choice = function(title, msg, choices) {
  if (Array.isArray(title)) throw new Error('Need at least a message and choices');
  if (Array.isArray(msg)) {
    choices = msg;
    msg = title;
    title = undefined;
  }

  if (!Array.isArray(choices)) throw new Error('No choices specified');

  var id = window.util.generateUUID();
  var $m = $('<div id="' + id + '" class="modal fade"><div class="modal-dialog"><div class="modal-content"><div class="modal-header"></div><div class="modal-body"></div><div class="modal-footer"></div></div></div></div>');
  var $footer = $m.find('.modal-footer');
  _.each(choices, function(choice) {
    var displayText = '', classNames = '';
    if (typeof choice === 'object') {
      displayText = choice.display || choice.name || choice.value;
      if (choice.class) classNames = choice.class;
    } else {
      displayText = choice.toString();
    }
    var $button = $('<button class="btn btn-default ' + classNames + '"></button>');
    $button.data('choice', choice).text(displayText);
    $footer.append($button);
  });

  return new Promise(function(resolve) {
    $m.data('result', false)
      .on('click', '.btn', function(e) {
        var choice = $(e.target).data('choice');
        $m.data('result', choice.value ? choice.value : choice);
        $m.modal('hide');
      })
      .css('display', 'none')
      .appendTo('body')
      .toggleClass('no-title', !title)
      .find('.modal-header').html(title).toggle(!!title).end()
      .find('.modal-body').html(msg).end()
      .one('hidden.bs.modal', function() { resolve($m.data('result')); })
      .modal('show');
  });
};

$.confirm = function(title, msg) {
  return $.confirmDefault(null, title, msg);
};

$.confirmDefault = function(button, title, msg) {
  var m = $('#confirm-modal');

  if (typeof msg === 'undefined' || msg === null) { msg = title; title = undefined; }

  if (!m.length)
  {
    m = $('<div id="confirm-modal" class="modal fade"><div class="modal-dialog"><div class="modal-content"><div class="modal-header"></div><div class="modal-body"></div><div class="modal-footer"><button type="button" class="btn btn-default" data-dismiss="modal">No</button><button type="button" class="btn btn-primary" data-dismiss="modal">Yes</button></div></div></div></div>')
        .on('click', '.btn', function(e) { m.data('result', $(e.target).is('.btn-primary'));  })
        .appendTo('body').modal({ show: false });
  }

  return new Promise(function(resolve) {
    m.data('result', false)
      .toggleClass('no-title', !title)
      .find('.modal-header').html(title).toggle(!!title).end()
      .find('.modal-body').html(msg).end()
      .one('hidden.bs.modal', function() { resolve(m.data('result')); })
      .one('shown.bs.modal', function() {
        if (!button) return;
        m.find('.modal-footer button').eq(button).focus();
      })
      .modal('show');
  });
};

$.msgbox = function(title, msg, cb) {
  var m = $('#msgbox-modal');

  if (typeof msg === 'function') { cb = msg; msg = title; title = undefined; }

  if (!m.length)
  {
    m = $('<div id="msgbox-modal" class="modal fade"><div class="modal-dialog"><div class="modal-content"><div class="modal-header"></div><div class="modal-body"></div><div class="modal-footer"><button type="button" class="btn btn-default" data-dismiss="modal">OK</button></div></div></div></div>')
        .appendTo('body').modal({ show: false });
  }

  m.data('result', false)
    .toggleClass('no-title', !title)
    .find('.modal-header').html(title).toggle(!!title).end()
    .find('.modal-body').html(msg).end()
    .one('hidden.bs.modal', function() {
      if (typeof cb === 'function')
      cb();
    })
    .modal('show');
};

$.inputbox = function(settingsOrMsg) {
  var m = $('#inputbox-modal');

  // title, msg, placeholder, defaultValue

  // if (typeof msg === 'function') {
  //   cb = msg;
  //   msg = title;
  //   placeholder = defaultValue = title = undefined;
  // }
  if (typeof settingsOrMsg === 'string') {
    settingsOrMsg = {
      msg: settingsOrMsg
    };
  }
  if (typeof settingsOrMsg !== 'object') {
    throw new Error('Invalid settings object');
  }
  

  if (!m.length)
  {
    m = $('<div id="inputbox-modal" class="modal fade"><div class="modal-dialog"><div class="modal-content"><div class="modal-header"></div><div class="modal-body"></div><div class="modal-footer"><button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button><button type="button" class="btn btn-primary">OK</button></div></div></div></div>')
        .on('click', '.btn', function(e) {
          if ($(e.target).is('.btn-primary')) {
            let value = m.find('#inputbox-modal-entry').val();
            if (typeof settingsOrMsg.validate === 'function') {
              if (!settingsOrMsg.validate(value)) {
                e.preventDefault();
                return;
              }
            }
            m.data('result', value);
          }
          m.modal('hide');
        })
        .appendTo('body').modal({ show: false });
  }

  let inputField = '<input class="form-control" id="inputbox-modal-entry" />';
  if (settingsOrMsg.useTextArea) {
    inputField = '<textarea class="form-control" id="inputbox-modal-entry"></textarea>';
  }
  let $body = $('<div class="row"><div class="col-md-12 form-group"><label class="control-label" for="inputbox-modal-entry"></label>' + inputField + '</div></div>');
  if (settingsOrMsg.msg) $body.find('label').text(settingsOrMsg.msg);
  $body.find('#inputbox-modal-entry')
    .attr('placeholder', settingsOrMsg.placeholder || '')
    .val(settingsOrMsg.defaultValue || '');

  return new Promise(function(resolve) {
    m.data('result', false)
      .toggleClass('no-title', !settingsOrMsg.title)
      .find('.modal-header').html(settingsOrMsg.title).toggle(!!settingsOrMsg.title).end()
      .find('.modal-body').empty().append($body).end()
      .one('hidden.bs.modal', function() {
        resolve(m.data('result'));
      })
      .modal('show');
  });
};

$(function() {

  $(document).on('show.bs.modal', '.modal', function() {
    var zIndex = 2050 + (10 * $('.modal:visible').length);
    $(this).css('z-index', zIndex);
    setTimeout(function() {
      $('.modal-backdrop:not(.modal-stack)')
        .css('z-index', zIndex - 1)
        .addClass('modal-stack');
    }, 0);
  });
  
  $(document).on('shown.bs.modal', '.modal', function() {
    openModals.push(this);
    $(this).find('input:alltext:not(.normalcase), textarea:not(.normalcase)').applyUpperCase();
    $(this).find('select[data-statefilter], select[data-zipfilter]').change();
    $(this).enableSelect2();
  });

  $(document).on('hidden.bs.modal', '.modal', function() {
    openModals.pop(); // shouldn't need reference to "this"
    $(this).find('textarea').each(function() {
      var id = $(this).data('tinymce_id');
      if (id) {
        tinyMCE.EditorManager.execCommand('mceRemoveEditor', true, id);
      }
    });
  });

  $(document).on('click', '[data-trigger=modal]', function(e) {
    var o = $(e.target),
        u = o.attr('href') || o.data('target');
    
    var modalOptions = {};
    var modalSize = o.data('modal-size');
    if (modalSize) modalOptions.size = modalSize;

    e.preventDefault();
    if (u) { $.modal(u, modalOptions); }
  });

});