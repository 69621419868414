'use strict';

var $ = window.jQuery;

$.fn.enableColumnSort = function() {
  var $columns = this.find('th[data-sortcolumn]');
  if (!$columns.length) return this;
  
  $columns.click(sortColumnClick);

  var query = window.util.getQuery();
  var $sortcolumn = this.find('th[data-sortcolumn][data-defaultsort]');
  if (query.sort) $sortcolumn = this.find('th[data-sortcolumn=' + query.sort + ']');
  
  if (query.sortDir) {
    if (query.sortDir === 'desc')
      $sortcolumn.addClass('desc');
  } else {
    if ($sortcolumn.data('defaultsortdir') === 'desc')
      $sortcolumn.addClass('desc');
  }
  $sortcolumn.addClass('sorted');
  return this;
};

function sortColumnClick(e) {
  e.preventDefault();
  var $column = $(e.currentTarget);
  var column = $column.data('sortcolumn');
  var query = window.util.getQuery();
  var defaultColumn = $column.parent().children('th[data-sortcolumn][data-defaultsort]').data('sortcolumn');
  var currentColumn = query.sort || defaultColumn;
  var currentDir = query.sortDir || $column.parent().children('th[data-sortcolumn=' + currentColumn + ']').data('defaultsortdir') || 'asc';
  
  if (currentColumn !== column) {
    if (column === defaultColumn) delete query.sort;
    else query.sort = column;
    delete query.sortDir;
  } else {
    // toggle direction
    var dir = (currentDir === 'desc') ? 'asc' : 'desc';
    if (dir === ($column.data('defaultsortdir') || 'asc')) delete query.sortDir;
    else query.sortDir = dir;
  }
  window.location = window.util.buildHref(null, query);
}

$(function() {
  $('.columns-sortable').enableColumnSort();
});