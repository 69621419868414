'use strict';

var PhoneNumber = require('google-libphonenumber'),
    PhoneUtil = PhoneNumber.PhoneNumberUtil.getInstance(),
    PNF = PhoneNumber.PhoneNumberFormat;

module.exports = {
  validate: function(number, format) {
    if (typeof format === 'undefined') format = 'US';
    try {
      let phone = PhoneUtil.parse(number, format);
      return PhoneUtil.isPossibleNumber(phone);
    } catch(err) {
      return false;
    }
  },

  format: function(number) {
    try {
      if (!number) return number;
      
      var meta = PhoneUtil.parse(number, 'ZZ'),
          code = meta.getCountryCode();
      
      if (code !== 1){
        number = PhoneUtil.format(meta, PNF.INTERNATIONAL);
      }else{
        number = PhoneUtil.format(meta, PNF.NATIONAL);
      }

      return number;
    }
    catch(err) {
      console.error(err, number);
      return number;
    }
  },

  getObject: function(number, errorLogLevel = 1) {
    try {
      return this.getObjectErr(number);
    }
    catch(err) {
      if (errorLogLevel > 0) {
        console.error(errorLogLevel < 2 ? err.message : err, number);
      }
      return number.replace(/[\+\-\s]/, '');
    }
  },

  getObjectErr: function(number) {
    var phn = PhoneUtil.parse(number, 'US');
    var obj = {};
    for(var field in phn.fields_) {
      if (!phn.fields_.hasOwnProperty(field)) continue;
      obj[phn.fields_[field].name_] = phn.fields_[field].defaultValue;
      if (phn.values_.hasOwnProperty(phn.fields_[field].tag_))
        obj[phn.fields_[field].name_] = phn.values_[phn.fields_[field].tag_];
    }
    return obj;
  }
};
