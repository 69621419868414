'use strict';

var $ = window.jQuery;

async function getOGTags(url) {
  return new Promise((resolve, reject) => {
    $.ajax({
      method: 'GET',
      url: '/og-tags',
      dataType: 'json',
      data: { url }
    })
    .done(function(data) {
      let xmlDoc = null;
      try {
        // Internet Explorer Only
        xmlDoc = new ActiveXObject('Microsoft.XMLDOM');
        xmlDoc.async = 'false';
        xmlDoc.loadXML(data.html);
      }
      catch (e1) {
        // Try better browsers
        try {
          let parser = new DOMParser();
          xmlDoc = parser.parseFromString(data.html, 'text/html');
        }
        catch (e2) {
          return reject(e2);
        }
      }

      if (!xmlDoc) {
        return reject(new Error('HTML Not Loaded!'));
      }

      let graphData = {};
      let metas = xmlDoc.getElementsByTagName('meta');
      for (let i = 0; i < metas.length; i++) {
        let prop = metas[i].getAttribute('property');
        if (prop && prop.match(/^og\:/)) {
          prop = prop.substring(3);
          if (prop.indexOf(':') < 0) {
            graphData[prop] = metas[i].getAttribute('content');
          }
        }
      }
      resolve(graphData);
    })
    .fail($.handleFormErrors());
  });
}

window.getOGTags = getOGTags;