'use strict';

let SignaturePad = require('signature_pad');
let $ = window.jQuery;

function resizeCanvas() {
  let ratio = Math.max(window.devicePixelRatio || 1, 1);
  this.width = this.offsetWidth * ratio;
  this.height = this.offsetHeight * ratio;
  this.getContext('2d').scale(ratio, ratio);
  $(this).data('jq-signature-pad').clear();
}

$.fn.resizeSignature = function() {
  this.each(() => {
    let evt = $(this).data('jq-signature-pad-resize');
    if (evt) {
      evt();
    }
  });
  return this;
};

$.fn.enableSignature = function() {
  this.each(() => {
    let $this = $(this);
    let evt = resizeCanvas.bind($this[0]);
    let pad = new SignaturePad($this[0]);
    $this.data('jq-signature-pad', pad);
    $this.data('jq-signature-pad-resize', evt);
    window.addEventListener('resize', evt, false);
    evt();
  });
  return this;
};

$.fn.disableSignature = function() {
  this.each(() => {
    let $this = $(this);
    let pad = $this.data('jq-signature-pad');
    let evt = $this.data('jq-signature-pad-resize');
    if (pad) {
      pad.off();
    }
    if (evt) {
      window.removeEventListener('resize', evt);
      $this.data('jq-signature-pad-resize', false);
    }
  });
  return this;
};

$.fn.clearSignature = function() {
  this.each(() => {
    let $this = $(this);
    let pad = $this.data('jq-signature-pad');
    if (pad) {
      pad.clear();
    }
  });
};

$.fn.getSignature = function() {
  let pad = this.data('jq-signature-pad');
  if (pad && !pad.isEmpty()) {
    let dataUrl = pad.toDataURL();
    let file = window.util.dataUrlToBlob(dataUrl);
    file.name = 'Signature';
    return file;
  }
  return false;
};