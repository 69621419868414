'use strict';

module.exports = function($, _) {

  var countryCount = null;
  // var stateProvinceCount = null;

  $(function() {

    $(document).on('change', 'select[data-statefilter]', function() {
      if (!countryCount) {
        countryCount = window.countries.length;
        // stateProvinceCount = window.stateProvinces.length;
      }
      var stateFilter = $(this).data('statefilter');
      let $form = $(this).closest('form');
      var $stateBox = $form.find('select[name="' + stateFilter + '"], input[name="' + stateFilter + '"]');
      var $stateLabel = $('label[for=' + $stateBox.attr('id') + ']');
      //var selectedState = $stateBox.val();
      var selectedData = {};
      if ($stateBox.prop('tagName') !== 'SELECT') {
        selectedData.id = $stateBox.data('sel-id');
        selectedData.text = $stateBox.val();
      } else {
        selectedData.id = $stateBox.val();
        selectedData.text = $stateBox.find(':selected').text();
      }

      var countryCode = $(this).val();
      var countryStates = [];
      if (countryCode) {
        var country = _.find(window.countries, function(c) { return c.code === countryCode; });
        if (country) {
          countryStates = _.filter(window.stateProvinces, function(s) {return s.country_id === country.id; });
          $stateLabel.text(country.state_label);
        }
      }

      var $newBox = null, attr = null;
      
      if (countryStates.length) {
        // convert to select box if necessary else empty it
        if ($stateBox.prop('tagName') !== 'SELECT') {
          $newBox = $('<select/>');
          for (var i = 0; i < $stateBox[0].attributes.length; i++) {
            attr = $stateBox[0].attributes[i];
            $newBox.attr(attr.name, attr.value);
          }
          $newBox.addClass('select2');
          $stateBox.replaceWith($newBox);
          $stateBox = $newBox;
          $stateBox.enableSelect2();
        } else {
          $stateBox.empty();
        }

        $('<option/>').appendTo($stateBox);
        var hasSelectedState = false;
        _.each(countryStates, function(state) {
          if (state.id == selectedData.id) {
            hasSelectedState = true;
          }

          $('<option/>', {value: state.id}).text(state.name)
            .appendTo($stateBox);
        });
        if (hasSelectedState) $stateBox.val(selectedData.id);
      } else {
        if ($stateBox.prop('tagName') === 'SELECT') {
          $stateBox.destroySelect2();
          $newBox = $('<input/>');
          for (var j = 0; j < $stateBox[0].attributes.length; j++) {
            attr = $stateBox[0].attributes[j];
            $newBox.attr(attr.name, attr.value);
          }
          $newBox.removeClass('select2');
          $stateBox.replaceWith($newBox);
          $stateBox = $newBox;
          $stateBox.val(selectedData.text ? selectedData.text.toLocaleUpperCase() : '');
          $stateBox.data('sel-id', selectedData.id);
          $stateBox.applyUpperCase();
          $stateBox.on('change', function() { $(this).data('sel-id', null); });
        }
      }
    });

    $(document).on('change', 'select[data-zipfilter]', function() {
      if (!countryCount) {
        countryCount = window.countries.length;
        // stateProvinceCount = window.stateProvinces.length;
      }

      var countryCode = $(this).val();
      if (countryCode) {
        var country = _.find(window.countries, function(c) { return c.code === countryCode; });
        if (country) {
          var $label = $('label[for=' + $(this).data('zipfilter') + ']');
          $label.text(country.zip_label);
        }
      }
    });

    $('select[data-statefilter], select[data-zipfilter]').change();
  });

};