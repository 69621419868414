'use strict';

module.exports = function($, _) {
  function crossbar() {
    var self = this,
        _auth,
        _session,
        _subs = {},
        _resolve,
        _promise = new window.Promise(function(resolve) {
          _resolve = resolve;
        }),
        _proxy = $({});

    var crossbarMethods = {
      // addLot: function(website_id, lot_id) {
      //   console.log('subscribed to lot ' + lot_id + ' on ' + website_id);
      //   self._subscribe(website_id, 'lot.extended.' + lot_id, function(args) { _proxy.trigger('extended', [lot_id, args[0]]); });
      // }
      live: {
        register: function(wid, id, ring) {
          console.log('subscribed to auction ' + id + ', ring ' + ring);
          self._subscribe(wid, 'live.' + id + '.info.' + ring, function(args) {
            var e = $.Event('liveInfo');
            e.auction_id = id;
            e.auction_ring = ring;
            e.website_id = wid;
            // self.trigger('liveInfo', args);
            self.trigger(e, args);
          });
          return self;
        },
        _setStatus: function(wid, aid, lid, status, includeRelated) {
          console.log('running set status');
          return self._call('live.setStatus', wid, _auth, aid, lid, status, !!includeRelated);
        },
        _setWinningBid: function(wid, aid, lotData, bidType) {
          console.log('running setWinningBid');
          if (!Array.isArray(lotData)) lotData = [lotData];
          return self._call('live.setWinningBid', wid, _auth, aid, lotData, bidType);
        },
        _deleteBid: function(wid, aid, lid, newStatus) {
          console.log('running deleteBid');
          return self._call('live.deleteWinningBid', wid, _auth, aid, lid, newStatus);
        },
        _setNoSale: function(wid, aid, lid, lotData, preventAdvance) {
          console.log('running setNoSale');
          return self._call('live.setNoSale', wid, _auth, aid, lid, true, null, lotData, preventAdvance);
        },
        _removeFromRing: function(wid, aid, lid, oldRing) {
          console.log('running removeFromRing');
          return self._call('live.removeFromRing', wid, _auth, aid, lid, oldRing);
        },
        _clearNoSale: function(wid, aid, lid, newStatus) {
          console.log('running clearNoSale');
          return self._call('live.setNoSale', wid, _auth, aid, lid, false, newStatus, null);
        },
        _updateBid: function(wid, aid, lid, bidData, releaseTo) {
          console.log('running updateBid');
          return self._call('live.editBid', wid, _auth, aid, lid, bidData, releaseTo);
        },
        _displaySlideshow: function(wid, aid, lid) {
          console.log('running displaySlideshow');
          return self._call('live.displaySlideshow', wid, _auth, aid, lid);
        },
        _setPricingStatus: function(wid, aid, ring, status) {
          console.log('running setPricingStatus');
          return self._call('live.setPricingOption', wid, _auth, aid, ring, status);
        },

        _passLot: function(wid, aid, lid) {
          console.log('running passLot');
          return self._call('live.passLot', wid, _auth, aid, lid);
        }
      },

      tasks: {
        completed: function() {
          console.log('subscribed to task completions');
          self._subscribe(null, 'task_completed', function(args) { self.trigger('taskCompleted', args); });
          return self;
        }
      },

      inventory: {
        register: function(wid, topic) {
          console.log('subscribed to inventory additions');
          self._subscribe(wid, topic, function(args) {self.trigger('invUpdate', args);});
        }
      }
    };

    function fixCrossbarMethods(obj) {
      var methods = Object.keys(obj);
      _.each(methods, function(methodName) {
        var mthdType = typeof obj[methodName];
        if (mthdType === 'object')
          fixCrossbarMethods(obj[methodName]);
        else if (mthdType !== 'function') {
          console.log('Expected function or object - got:', mthdType);
        } else {
          if (methodName.charAt(0) === '_') {
            // throw if not ready
            obj[methodName.substring(1)] = function() {
              if (!self.isReady()) throw new Error('Not connected to Crossbar');
              return obj[methodName].apply(self, arguments);
            };
          } else {
            obj['_' + methodName] = obj[methodName];
            obj[methodName] = function() {
              var methodArguments = arguments;
              if (self.isReady()) {
                obj['_' + methodName].apply(self, methodArguments);
              } else {
                self.then(function() {
                  return obj['_' + methodName].apply(self, methodArguments);
                });
              }
              return self;
            };
          }
        }
      });
    }

    fixCrossbarMethods(crossbarMethods);
    $.extend(self, crossbarMethods);
    $.extend(self, {
      on: function(type, fn) { _proxy.on(type, fn); return self; },
      trigger: function(type, args) { _proxy.trigger(type, args); },

      isReady: function() { return _session ? _session.isOpen : false; },

      then: function(cb) { return _promise.then(cb); },
      catch: function(cb) { return _promise.catch(cb); },

      _call: function(method) {
        var args = (arguments.length === 1 ? [arguments[0]] : Array.apply(null, arguments));
        args.shift();
        return _session.call('api:' + method, args)
        .catch(function(err) {
          console.log(err);
        });
      },

      _subscribe: function(website_id, topic, handler, options) {
        var topicString = website_id != null ? website_id + '.' + topic : topic;
        console.log('subscribing: ', 'api:' + topicString);
        _session.subscribe('api:' + topicString, handler, options)
        .then(function(sub) {
          if (!_subs.hasOwnProperty(topic)) {
            _subs[topic] = [];
          }
          _subs[topic].push(sub);
        });
      },

      _unsubscribe: function(topic) {
        var subs = _subs[topic];
        if (!subs) return;
        _.each(subs, function(sub) {
          _session.unsubscribe(sub);
        });
      },

      ontime: function(args) {
        _proxy.trigger('time', args[0]);
      },

      open: function(config) {
        var isSecure = (window.location.protocol === 'https:');
        var conn = new window.autobahn.Connection({
          transports: [
            { 'type': 'websocket', 'url': (isSecure ? config.wssuri : config.wsuri) },
            { 'type': 'longpoll', 'url': (isSecure ? config.httpsuri : config.httpuri) }
          ],
          realm: config.realm,
          authid: config.user,
          authmethods: ['wampcra'],
          onchallenge: function(session, method, extra) {
            return window.autobahn.auth_cra.sign(config.key, extra.challenge);
          }
        });

        conn.onopen = function(sess) {
          // occurs on Initial Connection and Reconnect
          console.log('Connected to Crossbar');
          var subscriptions = _session ? _session.subscriptions : [];

          _session = sess;
          _session.prefix('api', 'io.crossbar.' + config.realm);

          if (!subscriptions.length) {
            _session.subscribe('api:ontime', self.ontime);
          }

          _.each(subscriptions, function(subscription) {
            if (Array.isArray(subscription)) {
              _.each(subscription, function(sub) {
                _session.subscribe(sub.topic, sub.handler);
              });
            } else {
              _session.subscribe(subscription.topic, subscription.handler);
            }
          });

          _resolve();
        };

        conn.onclose = function(reason, details) {
          console.log('Crossbar connection failed:', reason, details);
        };

        _auth = config.auth;
        conn.open();
        return self;
      }
    });
  }

  window.Crossbar = new crossbar();
};