'use strict';

module.exports = function($) {

  $(function() {
    $(document).on('show.bs.dropdown', '.btn-group', function() {
      var zIndex = 2050 + (10 * $('.modal:visible').length);
      
      var $menu = $(this).find('ul.dropdown-menu');
      if ($menu.length) {
        setTimeout(function() {
          $menu.css('z-index', zIndex - 1);
        });
      }
    });

    $(document).on('show.bs.dropdown', function(e) {
      var $container = $(e.target);
      var $menu = $container.find('ul.dropdown-menu');
      var $button = $container.find('.btn.dropdown-toggle');

      if ($menu.hasClass('fixed')) {
        var $scrollParent = $container.scrollParent();
        var scrollParentOffset = $scrollParent.offset();
        $menu.css({
          top: scrollParentOffset.top + $button.outerHeight() - parseInt($scrollParent.css('padding-top')),
          left: parseInt($scrollParent.css('padding-left'))
        });
      }
    });

    $(document).on('shown.bs.tab', '[data-toggle=tab]', function(e) {
      let $aTarget = $(e.target);
      let state = history.state;
      if (!state) state = {};
      state.currentTab = $aTarget.attr('href');
      history.replaceState(state, null);
    });

    if (history.state && history.state.currentTab) {
      $("[data-toggle=tab][href='" + history.state.currentTab + "']").tab('show');
    }
  });

};