'use strict';

var $ = window.jQuery, toastr = window.toastr;

$.fn.sndInitTinyMCE = function(data, options) {
  options = $.extend({}, {
    statusbar: true,
    menubar: false,
    branding: false,
    images_upload_url: window.sndUploadURL,
    toolbar: 'undo redo | styleselect fontsizeselect | bold italic link removeformat | forecolor backcolor | alignleft aligncenter alignright | bullist numlist | outdent indent | hr image',
    fontsize_formats: '7pt 8pt 10pt 12pt 14pt 18pt 24pt 36pt',
    plugins: 'lists',
    automatic_uploads: true,
    file_picker_types: 'image',
    browser_spellcheck: true,
    // content_style: 'body { text-transform: uppercase; }'
    // resize: 'both'
  }, options);
  if (typeof data === typeof undefined) {
    data = {gallery: false};
  }
  if (data.gallery) {
    options.plugins = 'lists hr link textcolor sndtinygallery sndtinylinkgallery ';
    options.image_gallery_url = '/image-gallery/' + data.gallery;
    options.link_gallery_url = '/link-gallery/' + data.gallery;
  } else {
    options.plugins = 'lists hr link textcolor image imagetools ';
    options.images_upload_handler = function(blobInfo, success, failure) {
      var xhr;
      xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open('POST', window.sndUploadURL);
      xhr.onload = function() {
        var json;

        if (xhr.status != 200) return failure('HTTP Error: ' + xhr.status);
        json = JSON.parse(xhr.responseText);

        if (!json || typeof json.location != 'string') return failure('Invalid JSON: ' + xhr.responseText);
        success(json.location);
      };
      var fd = new FormData();
      fd.append('file', blobInfo.blob(), fileName(blobInfo));
      fd.append('sid', window.Auth.sid);
      fd.append('website_id', window.Auth.website_id);
      fd.append('bucket', window.sndUploadBucket);
      fd.append('thumbnails', false);
      fd.append('persist', false);
      fd.append('profile', 'image');
      
      xhr.send(fd);
    };
    options.file_picker_callback = function(cb) {
      var input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      
      input.onchange = function() {
        var file = this.files[0];
        
        var xhr;
        xhr = new XMLHttpRequest();
        xhr.withCredentials = false;
        xhr.open('POST', '#{uploadURL}');
        xhr.onload = function() {
          if (xhr.status != 200) return toastr.error('There was an error uploading the image');
          var json = JSON.parse(xhr.responseText);

          if (!json || typeof json.location != 'string') return toastr.error('There was an error uploading the image');
          cb(json.location, { title: json.title });
        };
        var fd = new FormData();
        
        var id = 'blobid' + (new Date()).getTime();
        var blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
        var blobInfo = blobCache.create(id, file);
        
        fd.append('file', blobInfo.blob(), file.name);
        fd.append('sid', window.Auth.sid);
        fd.append('website_id', window.Auth.website_id);
        fd.append('bucket', window.sndUploadBucket);
        fd.append('thumbnails', false);
        fd.append('persist', false);
        fd.append('profile', 'image');
        
        xhr.send(fd);
      };
      input.click();
    };
  }

  this.each(function() {
    var self = this;
    options.target = this;
    if (!$(this).hasClass('normalcase')) {
    //   options.content_style = '</style><script>document.write("test");</script><style type="text/css">';
    // } else {
      options.content_style = 'body { text-transform: uppercase; }';
    } else {
      delete options.content_style;
    }
    options.init_instance_callback = function(editor) {
      $(self).data('tinymce_id', editor.id);
      $('label[for="' + editor.id + '"]').click(function(e) {
        e.preventDefault();
        editor.focus();
      });
      editorInitialized(editor);
    };
    window.tinymce.init(options);
  });
};

// TinyMCE doesn't change the font size of bullets - this should compensate.
function editorInitialized(editor) {
  editor.on('ExecCommand', function(e) {
    var node = editor.selection.getNode();
    if (!node) return;

    // var $variableChildren = $(node).children('.tinymce-variable');
    // if (!$variableChildren.length && $(node).hasClass('tinymce-variable'))
    //   $variableChildren = $(node);
    
    if (e.command === 'FontSize') {
      var liChildren = $(node).children('li');
      liChildren.removeAttr('data-mce-style');
      liChildren.css('font-size', e.value);

      // editor.formatter.apply('lineheight', {value: e.value});
      // $variableChildren.css('font-size', e.value);
    }
    // $variableChildren.each(function() {
    //   $(this).attr('data-mce-style', $(this).attr('style'));
    // });
  });

  // Enforce caps for TinyMCE - unless specified otherwise
  if (!$(editor.targetElm).hasClass('normalcase')) {
    $(editor.iframeElement.contentDocument).find('body').on('keypress', function(e) {
      if (!e.ctrlKey && !e.metaKey && !e.altKey && !e.shiftKey) {
        var char = String.fromCharCode(e.charCode);
        var ucase = char.toLocaleUpperCase();
        if (char !== ucase) {
          e.preventDefault();
          $(this)[0].ownerDocument.execCommand('insertText', false, ucase);
        }
      }
    });
  }

  // editor.formatter.register({
  //   lineheight: {inline: 'span', styles: {'line-height': '%value'}}
  // });
}