'use strict';

var $ = window.jQuery, toastr = window.toastr;

$.fn.wizard = function wizard(action) {
  var args = (arguments.length === 1 ? [arguments[0]] : Array.apply(null, arguments));
  if (args.length) args.shift();

  var $wizardContainer = this.hasClass('.wizard') ? this : this.closest('.wizard');
  switch (action) {
    case 'replace':
      replaceStep($wizardContainer, args[0]); break;
    case 'goto':
      gotoStep($wizardContainer, args[0]); break;
    case 'register:next':
      registerNext.apply(this, args); break;
    case 'register:prev':
      registerPrev.apply(this, args); break;
    case 'register:btn':
      registerButton.apply(this, args); break;
    case 'enable':
      setupWizard.apply(this, args); break;
    default:
      return $wizardContainer;
  }
  return this;
};

function setupWizard() {
  this.on('click', 'a[data-step], button[data-step], .btn[data-step]', wizardNavigateStep);
}

function registerPrev() {
  // find the next button(s)
  var $wizardContainer = this.hasClass('wizard') ? this : this.closest('.wizard');
  var $nextButtons = $wizardContainer.find('.wizard-nav .prev');
  $nextButtons.off('click');
  $nextButtons.data('step', null);

  if (arguments.length < 2) {
    // hide the button - no action
    $nextButtons.addClass('hidden');
    showHideNav();
    return;
  }
  $nextButtons.removeClass('hidden');

  if (typeof arguments[0] === 'function') {
    var callback = arguments[0];
    $nextButtons.on('click', function(e) {
      e.preventDefault();
      stepLock = true;
      let $stepContainer = $wizardContainer.find('.step-container');
      disableButtons($wizardContainer);
      addStepOverlay($stepContainer);

      let result = callback.call($wizardContainer);

      $.when(result).then(function() {}, function() {}).then(function() {
        // we don't care what the result was, or whether it failed - just that it completed
        stepLock = false;
        var tasksRunning = [{}];
        while(stepAjax.length) tasksRunning.push(stepAjax.shift());
        $.when.apply($, tasksRunning).then(function(){}, function(){}).then(function() {
          // again we don't care the results - just that they are all finished
          enableButtons($wizardContainer);
          removeStepOverlay($stepContainer);
        });
      });
    });
  } else {
    $nextButtons.data('step', arguments[0]);
    $nextButtons.on('click', wizardNavigateStep);
  }

  if (arguments.length > 1) {
    $nextButtons.text(arguments[1]);
  }
  showHideNav();
}


var stepLock = false;
var stepAjax = [];
$(document).ajaxSend(function(e, jqXHR) {
  if (stepLock) stepAjax.push(jqXHR);
});


function registerNext() {
  // find the next button(s)
  var $wizardContainer = this.hasClass('wizard') ? this : this.closest('.wizard');
  var $nextButtons = $wizardContainer.find('.wizard-nav .next');
  $nextButtons.off('click');
  $nextButtons.data('step', null);

  if (arguments.length < 2) {
    // hide the button - no action
    $nextButtons.addClass('hidden');
    showHideNav();
    return;
  }
  $nextButtons.removeClass('hidden');

  if (typeof arguments[0] === 'function') {
    var callback = arguments[0];
    $nextButtons.on('click', function(e) {
      e.preventDefault();
      stepLock = true;
      let $stepContainer = $wizardContainer.find('.step-container');
      disableButtons($wizardContainer);
      addStepOverlay($stepContainer);

      let result = callback.call($wizardContainer);

      $.when(result).then(function() {}, function() {}).then(function() {
        // we don't care what the result was, or whether it failed - just that it completed
        stepLock = false;
        var tasksRunning = [{}];
        while(stepAjax.length) tasksRunning.push(stepAjax.shift());
        $.when.apply($, tasksRunning).then(function(){}, function(){}).then(function() {
          // again we don't care the results - just that they are all finished
          enableButtons($wizardContainer);
          removeStepOverlay($stepContainer);
        });
      });
    });
  } else {
    $nextButtons.data('step', arguments[0]);
    $nextButtons.on('click', wizardNavigateStep);
  }

  if (arguments.length > 1) {
    $nextButtons.text(arguments[1]);
  }
  showHideNav();
}

function showHideNav() {
  var $nav = $('.wizard-nav');
  $nav.removeClass('hidden');

  var $visible = $nav.find('button:visible, a:visible, .btn:visible');
  if (!$visible.length) 
    $nav.addClass('hidden');
}

function disableButtons($wizardContainer) {
  $wizardContainer.find('button:not(:disabled)').addClass('snd-wizard-disabled').prop('disabled', true);
  $wizardContainer.find('a').addClass('snd-wizard-disabled').each(function() {
    let $this = $(this);
    $this.data('sndWizHref', $this.attr('href')).attr('href', null);
  });
  $wizardContainer.addClass('sndWizNavDisabled');
}
function enableButtons($wizardContainer) {
  $wizardContainer.find('button.snd-wizard-disabled').prop('disabled', false).removeClass('snd-wizard-disabled');
  $wizardContainer.find('a.snd-wizard-disabled').each(function() {
    let $this = $(this);
    let href = $this.data('sndWizHref');
    if (href) $this.attr('href', href).data('sndWizHref', null);
  }).removeClass('snd-wizard-disabled');
  $wizardContainer.removeClass('sndWizNavDisabled');
}
function addStepOverlay($stepContainer) {
  let stepHeight = $stepContainer.outerHeight();
  let spinnerHeight = stepHeight - 50;
  let $overlay = $('<div class="wait-step"><i class="fas fa-new-spinner fa-new-spin" style="font-size: ' + spinnerHeight + 'px;" /></div>');
  $stepContainer.append($overlay);
}
function removeStepOverlay($stepContainer) {
  $stepContainer.find('.wait-step').remove();
}

function gotoStep($wizardContainer, stepUrl) {
  // disable buttons, put overlay over step-container
  var $stepContainer = $wizardContainer.find('.step-container');
  disableButtons($wizardContainer);
  addStepOverlay($stepContainer);
  setTimeout(function() {
    $.ajax({
      method: 'GET',
      url: stepUrl
    })
    .done(function(html) {
      doReplaceStep($wizardContainer, $stepContainer, html);
    })
    .fail(function(result) {
      toastr.warning(result.responseText);
    })
    .always(function() {
      enableButtons($wizardContainer);
      removeStepOverlay($stepContainer);
    });
  }, 50);
}

function replaceStep($wizardContainer, html) {
  var $stepContainer = $wizardContainer.find('.step-container');
  doReplaceStep($wizardContainer, $stepContainer, html);
}

function doReplaceStep($wizardContainer, $stepContainer, html) {
  setTimeout(function() {
    $stepContainer.html(html);
    $stepContainer.find('input:alltext:not(.normalcase)').applyUpperCase();
    $stepContainer.enableSelect2();

    $wizardContainer.find('.wizard-nav .left > *:not(.prev)').remove();
    $wizardContainer.find('.wizard-nav .right > *:not(.next)').remove();
  }, 50);
}

function registerButton(position, id, $newButton) {
  // find the next button(s)
  var $wizardContainer = this.hasClass('wizard') ? this : this.closest('.wizard');
  // var $nextButtons = $wizardContainer.find('.wizard-nav .next');
  // $nextButtons.off('click');
  // $nextButtons.data('step', null);
  var $button = $wizardContainer.find('.wizard-nav .' + position + ' #' + id);
  $button.remove();
  if (arguments.length < 3) {
    // hide the button - no action
    return;
  }
  
  $newButton.attr('id', id);
  $newButton.detach();
  var $buttonContainer = $wizardContainer.find('.wizard-nav .' + position);
  if (position === 'left') {
    $buttonContainer.append($newButton);
  } else if (position === 'right') {
    $buttonContainer.prepend($newButton);
  }
}

function wizardNavigateStep(e) {
  e.preventDefault();
  var $wizardContainer = $(this).closest('.wizard');
  if ($wizardContainer.hasClass('sndWizNavDisabled'))
    return;
  
  gotoStep($wizardContainer, $(this).data('step'));
}

$(function() {
  $('.wizard').wizard('enable');
});