'use strict';

let $ = window.jQuery;

function syncSelectedColumns(columns) {
  if (columns) {
    this.each(function(idx, chooser) {
      var $chooser = $(chooser);
      var selColumnKeys = $chooser.column_chooser('selected');

      for (var col in columns) {
        if (!columns.hasOwnProperty(col)) continue;
        columns[col].selected = (selColumnKeys.indexOf(col) > -1);
      }
    });
  } else {
    this.each(function(idx, chooser) {
      var $chooser = $(chooser);
      var selColumnKeys = $chooser.column_chooser('selected');

      $('[data-column_chooser]').addClass('hidden');
      selColumnKeys.forEach(function(columnKey) {
        $('[data-column_chooser="' + columnKey + '"]').removeClass('hidden');
      });
    });
  }
}

function activateColumnChooser() {
  this.each(function() {
    var $chooser = $(this),
    $menu = $chooser.find('.dropdown-menu'),
    $columns = $chooser.find('.dropdown-menu li a'),
    tableName = $chooser.data('table');

    $menu.click(function(e) {
      e.stopPropagation();
    });

    if ($chooser.hasClass('overflow')) {
      $chooser.on('show.bs.dropdown', function() {
        var bounds = $chooser.get(0).getBoundingClientRect();
        $menu.css({
          top: bounds.bottom,
          left: null,
          right: window.innerWidth - bounds.right
        });
      });
    }

    $columns.on('click', function(e) {
      e.preventDefault();
      
      var $this = $(this),
      columnKey = $this.data('col');

      // toggle li class
      $this.parent().toggleClass('selected');
      // send updated list to server for preferences
      var selColumnKeys = $chooser.column_chooser('selected');
      
      if (tableName) {
        // Table name was set - we can persist the selection
        $.ajax({
          method: 'POST',
          url: '/settings/columns/' + tableName,
          data: {columns: selColumnKeys}
        })
        .done(function() {
          // get corresponding column cells (for display/hide)
          $('[data-column_chooser="' + columnKey + '"]').toggleClass('hidden');
          $chooser.trigger('columns.changed');
        })
        .fail($.handleFormErrors());
      } else {
        // no table name was set - just update the page
        $('[data-column_chooser="' + columnKey + '"]').toggleClass('hidden');
        $chooser.trigger('columns.changed');
      }
    });
  });
}

$.fn.column_chooser = function columnChooser(action, options) {
  if (action === 'sync') syncSelectedColumns.call(this, options);
  if (action === 'selected') {
    return this.find('.dropdown-menu li.selected a').map(function() {
      return $(this).data('col');
    }).get();
  }
  if (action === 'get') {
    let columns = {};
    this.find('.dropdown-menu li').each(function() {
      let isSelected = $(this).hasClass('selected');
      let $a = $(this).find('a');
      let column = $a.data('col');
      let display = $a.text();
      columns[column] = {
        display: display,
        selected: isSelected
      };
    });
    return columns;
  }
  if (!action) activateColumnChooser.call(this);
};

$('.column-chooser').column_chooser();