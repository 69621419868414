'use strict';

var $ = window.jQuery, _ = window._;

function columnFilterSearchClick(e) {
  e.preventDefault();

  var query = window.util.getQuery();
  var foundInput = $('.search_row').getSearchParameters(query);
  
  if (foundInput) {
    var evt = {path: window.location.pathname, handled: false};
    $('.search_row').trigger('snd:column-filter-path', [evt]);

    if (!evt.handled) {
      evt.path = evt.path.replace(/\/\d+$/g, '');
    }
    
    window.location = window.util.buildHref(evt.path, query);
  }
}

$.fn.getSearchParameters = function(query) {
  var foundInput = false;
  this.find(':input').each(function() {
    var $this = $(this);
    if ($this.is('button')) return;
    foundInput = true;

    var value = $this.val();
    var name = $this.attr('name');
    var qIdx = 'search['+name+']';
    query[qIdx] = value;

    if (!query[qIdx] || ((Array.isArray(query[qIdx]) && query[qIdx].length < 1) || (!Array.isArray(query[qIdx]) && query[qIdx].trim() === '')) || query[qIdx] === $(this).data('defaultsearchvalue'))
      delete query[qIdx];
    
    if (Array.isArray(query[qIdx])) {
      var tmp = [];
      _.each(query[qIdx], function(d) { tmp.push(d); });
      query[qIdx] = tmp;
    }
  });
  if (foundInput) return query;
  return foundInput;
};

$.fn.enableColumnFilter = function() {
  var self = this;
  this.find('button.search').click(columnFilterSearchClick);
  this.keypress(function(e) {
    if (e.which !== 13) return;
    e.stopPropagation();
    self.find('button.search').first().click();
  });

  var query = window.util.getQuery();
  _.forOwn(query, function(value, key) {
    if (key.length < 8) return;
    var k = key.substr(7, key.length - 8);
    
    var input = self.find('[name="'+k+'"]');
    if (input.prop('tagName') === 'SELECT' && input.hasAttr('multiple')) {
      var values = value.split(/,/g);
      $.each(values, function(i, e) {
        input.find('option[value="' + e + '"]').prop('selected', true);
      });
      input.change();
    } else {
      input.val(value).change();
    }
  });
  return this;
};

$(function() {
  $('.search_row').enableColumnFilter();
});