'use strict';

var tempQuery = false, _ = window._;

var PhoneUtilities = require('lib/phone');
var formatLuxonDuration = require('lib/durationFormat');
var slideshow = require('lib/slideshow_funcs');
var s3url = require('lib/s3Urls');

var util = {
  slideshow_fn: slideshow,
  getS3Url: function(image, size, options) {
    return s3url.getS3Url(image, size, options);
  },
  plainText: function(htmlContent) {
    if (!htmlContent) return '';
    let d = document.createElement('div');
    d.innerHTML = htmlContent;
    return d.innerText || '';
  },
  safeAttr: function(attr) {
    if (!attr) return attr;
    return attr.toString().replace(/([ #;&,.+*~\':"!^$[\]()=>|\/@])/g, '\\$1');
  },
  stringify: function(obj) {
    var str = JSON.stringify(obj);
    return str.replace('"', '&quot;');
  },
  formatFileSize: function(bytes, binary) {
    if (typeof bytes !== 'number') bytes = parseInt(bytes);

    var thresh = !binary ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) return bytes + ' B';
    var units = !binary ?
      ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] :
      ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    
    var u = -1;
    do {
      bytes /= thresh;
      u++;
    } while(Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
  },
  formatDuration: function(value) {
    return formatLuxonDuration(value);
  },
  formatCurrency: function(amount, dollarSign){
    if (dollarSign === true || typeof dollarSign === 'undefined') dollarSign = '$';
    if(amount){
      if(typeof amount !== 'string' && amount.toFixed(2) != null){
        amount = amount.toFixed(2).toString();
      }
      return (dollarSign ? dollarSign : '') + amount.replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    }
    return (dollarSign ? dollarSign : '') + '0.00';
  },
  formatPercent: function(value, decimals, sign) {
    if (!decimals && decimals !== 0) decimals = 2;
    if (!sign && sign !== false) sign = true;

    if (value) {
      value = (value * 100).toFixed(decimals).toString();
      return value.replace(/(\d)(?=(\d{3})+\.)/g, '$1,') + (sign ? '%' : '');
    }
    return '0.'.padEnd(decimals + 2, '0') + (sign ? '%' : '');
  },
  formatNumber: function(num) {
    if (typeof num === 'string') num = parseFloat(num);
    if (num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return num;
  },
  formatContactPhone(record) {
    if (!record) return '';
    return this.formatPhone(record.phone_number, record.phone_ext);
  },
  formatPhone(number, ext) {
    var ret = '';
    if (number) {
      ret += PhoneUtilities.format(number);

      if (ext) {
        ret += ' ext. ' + ext;
      }
    }
    return ret;
  },
  safeGet: function(data, pathArray) {
    var result = data;

    for(var i = 0; i < pathArray.length; i++) {
      var tmp = result[pathArray[i]];
      if(tmp !== undefined) {
        result = tmp;
      } else {
        return null;
      }
    }

    return result;
  },
  generateUUID: function () {
    var d = new Date().getTime();
    if(window.performance && typeof window.performance.now === "function"){
      d += performance.now(); //use high-precision timer if available
    }
    var uuid = 'xxxxxxxx_xxxx_4xxx_yxxx_xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (d + Math.random()*16)%16 | 0;
      d = Math.floor(d/16);
      return (c==='x' ? r : (r&0x3|0x8)).toString(16);
    });
    return uuid;
  },
  IsJSON: function(data) {
    try {
      var json = JSON.parse(data); // eslint-disable-line no-unused-vars
      return true;
    } catch(e) {
      return false;
    }
  },
  setTempQuery: function(query) {
    tempQuery = query;
  },
  fixQSArrays: function(body, useObjects) {
    let newBody = {}, newIndex = {};
    _.forOwn(body, (value, key) => {
      let idx = key.lastIndexOf('[]');
      let handledLastAutoSquare = false;
      while (idx > -1) {
        if (!handledLastAutoSquare && Array.isArray(value)) {
          handledLastAutoSquare = true;
          key = key.slice(0, idx);
          idx = key.lastIndexOf('[]');
          continue;
        }
        let priorKey = key.substring(0, idx);
        if (!newIndex.hasOwnProperty(priorKey)) newIndex[priorKey] = 0;
        let arrayIndex = newIndex[priorKey]++;
        if (key.length > idx + 2)
          key = key.slice(0, idx) + '[' + arrayIndex + ']' + key.slice(idx + 2);
        else
          key = key.slice(0, idx) + '[' + arrayIndex + ']';
        idx = key.lastIndexOf('[]');
      }

      if (useObjects)
        _.setWith(newBody, key, value, Object);
      else
        _.set(newBody, key, value);
    });
    return newBody;
  },
  getQuery: function() {
    if (tempQuery) return tempQuery;
    if (!window.location.search) return {};
    var query = {},
        qs = (window.location.search[0] === '?' ? window.location.search.substr(1) : window.location.search).split('&');
    for(var i = 0; i < qs.length; i++) {
      var pv = qs[i].split('=');
      query[decodeURIComponent(pv[0])] = decodeURIComponent(pv[1] || '');
    }
    return query;
  },
  formatDBDuration: function(age) {
    if (!age) return '';
    var parts = [];
    if (age.years) parts.push(`${age.years} years`);
    if (age.months) parts.push(`${age.months} months`);
    if (age.days) parts.push(`${age.days} days`);

    if (!age.hours) age.hours = 0;
    if (!age.minutes) age.minutes = 0;
    if (!age.seconds) age.seconds = 0;
    if (!age.milliseconds) age.milliseconds = 0;
    age.milliseconds = Math.ceil(age.milliseconds);
    let timeParts = [];
    if (age.hours) timeParts.push(age.hours);
    if (age.hours || age.minutes)
      timeParts.push(age.hours ? age.minutes.toString().padStart(2, '0') : age.minutes);
    if (age.hours || age.minutes || age.seconds || age.milliseconds)
      timeParts.push((age.hours || age.minutes ? age.seconds.toString().padStart(2, '0') : age.seconds) + '.' + age.milliseconds);
    // parts.push(`${age.hours}:${age.minutes}:${age.seconds}.${age.milliseconds}`);
    parts.push(timeParts.join(':'));
    return parts.join(' ');
  },
  buildQueryString: function(query, prefix) {
    if (!query) query = {};

    let parts = [];
    for (let key in query) {
      if (!query.hasOwnProperty(key)) continue;

      let value = query[key];
      let name = prefix ? prefix + '[' + key + ']' : key;

      parts.push(
        (value !== null && typeof value === 'object') ?
        this.buildQueryString(value, name) :
        encodeURIComponent(name) + '=' + encodeURIComponent(value)
      );
    }

    return parts.length ? (prefix ? '' : '?') + parts.join('&') : '';
  },
  buildHref: function(path, query, hash, host) {
    // var parts = [];
    // for (var key in query) {
    //   if (!query.hasOwnProperty(key)) continue;
    //   parts.push(encodeURIComponent(key) + '=' + encodeURIComponent(query[key]));
    // }

    // var qs = parts.length ? '?' + parts.join('&') : '';
    let qs = this.buildQueryString(query);
    path = path ? path : window.location.pathname;
    hash = hash ? hash : window.location.hash;
    if (path && path[0] !== '/') path = '/' + path;
    if (host) {
      return host + path + qs + (hash ? '#' : '') + hash;
    }
    return window.location.protocol + '//' +
      window.location.hostname + 
      path + qs + (hash ? '#' : '') + hash;
  },
  dataUrlToBlob(url) {
    let byteString;

    let splitUrl = url.split(',');
    if (splitUrl[0].indexOf('base64') >= 0) {
      byteString = atob(splitUrl[1]);
    } else {
      byteString = decodeURIComponent(splitUrl[1]);
    }

    let mimeString = splitUrl[0].split(':')[1].split(';')[0];
    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    let blob = new Blob([ia], {type: mimeString});
    blob.url = url;
    return blob;
  }
};

window.util = util;
window.Util = util;
window.utils = util;
window.Utils = util;
window.localUtil = util;