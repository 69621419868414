'use strict';
/* global moment */

var $ = window.jQuery,
    toastr = window.toastr,
    _ = window._;

var dlParser = function dlParser() {
  this.issuers = {
    "636033": {id: 1, name: "Alabama", abrv: "AL"},
    "646059": {id: 2, name: "Alaska", abrv: "AK"},
    "604427": {id: null, name: "American Samoa", abrv: "AS"},
    "636026": {id: 3, name: "Arizona", abrv: "AR"},
    "636021": {id: 4, name: "Arkansas", abrv: "AK"},
    "636028": {id: null, name: "British Columbia", abrv: null}, //"BC"},
    "636014": {id: 5, name: "California", abrv: "CA"},
    "636056": {id: null, name: "Caohulia", abrv: null}, //"CA"},
    "636020": {id: 6, name: "Colorado", abrv: "CO"},
    "636006": {id: 7, name: "Connecticut", abrv: "CT"},
    "636043": {id: 55, name: "District of Columbia", abrv: "DC"},
    "636011": {id: 8, name: "Delaware", abrv: "DE"},
    "636010": {id: 9, name: "Florida", abrv: "FL"},
    "636055": {id: 10, name: "Georgia", abrv: "GA"},
    "636019": {id: null, name: "Guam", abrv: "GU"},
    "636047": {id: 11, name: "Hawaii", abrv: "HI"},
    "636057": {id: null, name: "Hidalgo", abrv: null},//"HI"},
    "636050": {id: 12, name: "Idaho", abrv: "ID"},
    "636035": {id: 13, name: "Illinois", abrv: "IL"},
    "636037": {id: 14, name: "Indiana", abrv: "IN"},
    "636018": {id: 15, name: "Iowa", abrv: "IA"},
    "636022": {id: 16, name: "Kansas", abrv: "KS"},
    "636046": {id: 17, name: "Kentucky", abrv: "KY"},
    "636007": {id: 18, name: "Louisiana", abrv: "LA"},
    "636041": {id: 19, name: "Maine", abrv: "ME"},
    "636048": {id: null, name: "Manitoba", abrv: null},//"MB"},
    "636003": {id: 20, name: "Maryland", abrv: "MD"},
    "636002": {id: 21, name: "Massachusetts", abrv: "MA"},
    "636032": {id: 22, name: "Michigan", abrv: "MI"},
    "636038": {id: 23, name: "Minnesota", abrv: "MN"},
    "636051": {id: 24, name: "Mississippi", abrv: "MS"},
    "636030": {id: 25, name: "Missouri", abrv: "MO"},
    "636008": {id: 26, name: "Montana", abrv: "MT"},
    "636054": {id: 27, name: "Nebraska", abrv: "NE"},
    "636049": {id: 28, name: "Nevada", abrv: "NV"},
    "636017": {id: null, name: "New Brunswick", abrv: null},//"NB"},
    "636039": {id: 29, name: "New Hampshire", abrv: "NH"},
    "636036": {id: 30, name: "New Jersey", abrv: "NJ"},
    "636009": {id: 31, name: "New Mexico", abrv: "NM"},
    "636001": {id: 32, name: "New York", abrv: "NY"},
    "636016": {id: null, name: "Newfoundland", abrv: null},
    "636004": {id: 33, name: "North Carolina", abrv: "NC"},
    "636034": {id: 34, name: "North Dakota", abrv: "ND"},
    "636013": {id: null, name: "Nova Scotia", abrv: null}, //"NS"},
    "636023": {id: 35, name: "Ohio", abrv: "OH"},
    "636058": {id: 36, name: "Oklahoma", abrv: "OK"},
    "636012": {id: null, name: "Ontario", abrv: null},//"ON"},
    "636029": {id: 37, name: "Oregon", abrv: "OR"},
    "636025": {id: 38, name: "Pennsylvania", abrv: "PA"},
    "604426": {id: null, name: "Prince Edward Island", abrv: null},//"PE"},
    "604428": {id: null, name: "Quebec", abrv: null},//"QC"},
    "636052": {id: 39, name: "Rhode Island", abrv: "RI"},
    "636044": {id: null, name: "Saskatchewan", abrv: null},//"SK"},
    "636005": {id: 40, name: "South Carolina", abrv: "SC"},
    "636042": {id: 41, name: "South Dakota", abrv: "SD"},
    "636053": {id: 42, name: "Tennessee", abrv: "TN"},
    "636027": {id: null, name: "State Department (USA)", abrv: null},
    "636015": {id: 43, name: "Texas", abrv: "TX"},
    "636062": {id: null, name: "US Virgin Islands", abrv: "VI"},
    "636040": {id: 44, name: "Utah", abrv: "UT"},
    "636024": {id: 49, name: "Vermont", abrv: "VT"},
    "636000": {id: 50, name: "Virginia", abrv: "VA"},
    "636045": {id: 51, name: "Washington", abrv: "WA"},
    "636061": {id: 52, name: "West Virginia", abrv: "WV"},
    "636031": {id: 53, name: "Wisconsin", abrv: "WI"},
    "636060": {id: 54, name: "Wyoming", abrv: "WY"},
    "604429": {id: null, name: "Yukon", abrv: null}//"YT"}
  };

  this.elements = {
    1: {
      "DAQ": "license_nbr",
      "DAB": "last_name",
      "DAC": "first_name",
      "DBB": "birth_date",
      "DBD": "issue_date",
      "DBA": "exp_date",
      "DAG": "address",
      "DAI": "city",
      "DAJ": "state",
      "DAK": "zip_code",
      "DBC": "gender"
    },
    2: {
      "DAQ": "license_nbr",
      "DCS": "last_name",
      "DCT": "first_name",
      "DBB": "birth_date",
      "DBD": "issue_date",
      "DBA": "exp_date",
      "DAG": "address",
      "DAI": "city",
      "DAJ": "state",
      "DAK": "zip_code",
      "DBC": "gender"
    },
    3: {
      "DAQ": "license_nbr",
      "DCS": "last_name",
      "DCT": "first_name",
      "DBB": "birth_date",
      "DBD": "issue_date",
      "DBA": "exp_date",
      "DAG": "address",
      "DAI": "city",
      "DAJ": "state",
      "DAK": "zip_code",
      "DBC": "gender"
    },
    4: {
      "DAQ": "license_nbr",
      "DCS": "last_name",
      "DAC": "first_name",
      "DBB": "birth_date",
      "DBD": "issue_date",
      "DBA": "exp_date",
      "DAG": "address",
      "DAI": "city",
      "DAJ": "state",
      "DAK": "zip_code",
      "DBC": "gender"
    },
    5: {
      "DAQ": "license_nbr",
      "DCS": "last_name",
      "DAC": "first_name",
      "DBB": "birth_date",
      "DBD": "issue_date",
      "DBA": "exp_date",
      "DAG": "address",
      "DAI": "city",
      "DAJ": "state",
      "DAK": "zip_code",
      "DBC": "gender"
    },
    6: {
      "DAQ": "license_nbr",
      "DCS": "last_name",
      "DAC": "first_name",
      "DBB": "birth_date",
      "DBD": "issue_date",
      "DBA": "exp_date",
      "DAG": "address",
      "DAI": "city",
      "DAJ": "state",
      "DAK": "zip_code",
      "DBC": "gender"
    },
    7: {
      "DAQ": "license_nbr",
      "DCS": "last_name",
      "DAC": "first_name",
      "DBB": "birth_date",
      "DBD": "issue_date",
      "DBA": "exp_date",
      "DAG": "address",
      "DAI": "city",
      "DAJ": "state",
      "DAK": "zip_code",
      "DBC": "gender"
    },
    8: {
      "DAQ": "license_nbr",
      "DCS": "last_name",
      "DAC": "first_name",
      "DBB": "birth_date",
      "DBD": "issue_date",
      "DBA": "exp_date",
      "DAG": "address",
      "DAI": "city",
      "DAJ": "state",
      "DAK": "zip_code",
      "DBC": "gender"
    }
  };
};

var parser = new dlParser();
$.fn.inputreader.defaults.timeout = 25;
$.fn.driversLicenseScan = function(action, fields, context) {
  if (action === 'destroy') {
    console.log('destroying dl scanner');
    this.off('inputreader:scan', scan).inputreader('stop').data('dl-scan-settings', null);
    return this;
  }
  if (!fields) {
    console.log('No fields defined for license fields');
    return this;
  }
  if (typeof fields !== 'object') {
    console.log('Field definitions are expected to be an object in the format {"dl_field": "input_name"}');
    return this;
  }
  var settings = {
    fields: fields,
    context: context
  };
  return this.data('dl-scan-settings', settings).on('inputreader:scan', scan).inputreader('listen');
};

function scan(e) {
  if (e.scandata.length < 2) return;
  e.preventDefault();
  try {
    if (e.scandata.length < 12) throw new Error('Invalid barcode format');
    var data = parser.parse(e.scandata);
    if (data === null) throw new Error('Invalid barcode format');

    console.log('Scan Result: ', data);
    
    var settings = $(e.currentTarget).data('dl-scan-settings');
    for (var field in settings.fields) {
      if (!settings.fields.hasOwnProperty(field)) continue;
      var name = settings.fields[field];
      field = field.replace(/\//g, '.');
      var fieldValue = _.get(data, field, null);
      if (fieldValue !== null) {
        $('[name="' + name + '"]', settings.context).each(function() {
          var $this = $(this);
          var tagName = $this.prop('tagName');
          if (tagName === 'SELECT') {
            $this.val(fieldValue).trigger('change');
            return;
          } else if (tagName === 'TEXTAREA') {
            var tinyId = $this.data('tinymce_id');
            if (tinyId) {
              window.tinyMCE.get(tinyId).setContent(fieldValue);
            } else {
              $this.val(fieldValue).trigger('change input');
            }
            return;
          }
          $this.val(fieldValue).trigger('change input');
        });
      }
    }
    $(e.currentTarget).trigger('dl:scan-completed');
  } catch(err) {
    toastr.error(err.message);
  }
}

dlParser.prototype.parse = function parse(val) {
  var result = {};
  
  var comp_ind = val[0];
  var elem_sep = val[1];
  var rec_sep = val[2];
  var seg_sep = val[3];
  var file_type = val.substr(4, 5);

  if (comp_ind !== '@' && file_type !== 'ANSI ') {
    throw new Error('Invalid AAMVA format');
  }

  var iin = val.substr(9, 6);
  var version = parseInt(val.substr(15, 2));
  var juris_version = 0;
  var num_entries = 0;

  result.issuer = this.issuers[iin];

  if (version === 1) num_entries = parseInt(val.substr(17, 2));
  else {
    juris_version = parseInt(val.substr(17, 2));
    num_entries = parseInt(val.substr(19, 2));
  }

  for (var i = 0, o = (version === 1 ? 19 : 21); i < num_entries; i++) {
    var entry_type = val.substr(o, 2);
    var entry_offset = parseInt(val.substr(o + 2, 4));
    var entry_length = parseInt(val.substr(o + 6, 4));
    o += 10;

    if (entry_type === 'DL') {
      var elements = val.substr(entry_offset, entry_length - 2).split(elem_sep);
      for (var x = 0; x < elements.length; x++) {
        var element = elements[x];
        var elem_type = element.substr(0, 3);
        var elem_val = element.substr(3);

        if (this.elements[version] && this.elements[version][elem_type]) {
          var elem_name = this.elements[version][elem_type];
          switch (elem_type) {
            case 'DAQ':
              elem_val = elem_val.replace(/\s/g, '');
              break;
            case 'DBB':
            case 'DBD':
            case 'DBA':
              elem_val = moment(elem_val, 'MMDDYYYY').format('MM/DD/YYYY');
              break;
            case 'DBC':
              elem_val = (elem_val === '1' ? 'M' : 'F');
          }
          
          result[elem_name] = elem_val.trim();
        }
      }
    }
  }

  return result;
};