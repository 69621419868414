'use strict';

var $ = window.jQuery,
    _ = window._;

function formatCategoryDropdown(option) {
  if (!option.id || !option.element) return option.text;

  var indent = parseInt($(option.element).data('indent'));
  var $span = $('<span />').css('padding-left', (indent * 15) + 'px');
  if ($(option.element).hasAttr('data-deactivated'))
    $span.addClass('text-danger');
  return $span.text(option.text);
}

function formatCategoryDisplay(option) {
  if (!option.id || !option.element) return option.text;
  return $(option.element).data('path');
}

function categoryMatcher(params, data, checkType) {
  if ($.trim(params.term) === '') {
    return data;
  }

  var $element = $(data.element);
  var hasChildMatch = false;
  if ((!checkType || checkType === 'child') && $element.hasAttr('data-has-children')) {
    var $children = $element.closest('select').find('option[data-parent=' + data.id +']');
    var children = $children.map(function() {
      return {
        id: this.value,
        element: this,
        text: this.text
      };
    }).get();

    for (var c = children.length - 1; c >= 0; c--) {
      var childResult = categoryMatcher(params, children[c], 'child');
      if (childResult) {
        hasChildMatch = true;
        break;
      }
    }
  }

  var hasParentMatch = false;
  if ((!checkType || checkType === 'parent') && $element.hasAttr('data-parent')) {
    var parentId = $element.data('parent');
    var $parent = $element.closest('select').find('option[value=' + parentId + ']');

    var parent = {
      id: $parent.attr('value'),
      element: $parent.get(0),
      text: $parent.text()
    };
    var parentMatch = categoryMatcher(params, parent, 'parent');
    if (parentMatch) {
      hasParentMatch = true;
    }
  }

  if (hasChildMatch || hasParentMatch) return data;

  var original = data.text.toUpperCase();
  var term = params.term.toUpperCase();

  if (original.indexOf(term) > -1) {
    return data;
  }
  return null;
}

function formatConsignorDropdown(user) {
  if(user.unconfirmed) {
    return $('<div><div style="display: inline-block;">' + user.text + ' </div><i class="fas fa-new-fw fa-new-exclamation-triangle" style="color:#ffa442;" title="unconfirmed"/></div>');
  } else {
    return user.text;
  }
}

function formatCheckDropdown(option) {
  if (!option.id) return option.text;

  var icon = '';
  switch (option.id) {
    case '0': icon = 'fa-new-square'; break;
    case '1': icon = 'fa-new-check-square'; break;
  }
  return $('<i class="far fa-new-fw" />').addClass(icon);
}

let $fnVal = $.fn.val;
$.fn.val = function(valOrFn) {
  let $this= $(this);

  if (!$this.length || !$this.hasAttr('data-preserve-tag-order'))
    return $fnVal.apply(this, arguments);
  
  // if we got here $this is a select2 with `data-preserve-tag-order`

  if (!arguments.length) {
    // get the value
    let data = [];
    $this.find('option:selected').each(function() {
      let opt = $(this).data('data');
      data.push(opt);
    });

    let dataOrder = $this.data('ordered-data');
    if (!dataOrder) dataOrder = [];
    return dataOrder.map(id => data.find(opt => opt._resultId == id).id);
  }
  
  if (typeof valOrFn === 'function') {
    let currentVal = $this.val();
    valOrFn = valOrFn.apply(this, [0, currentVal]);
    if (typeof valOrFn === 'function')
      throw new Error('Expected a value');
    if (!Array.isArray(valOrFn))
      valOrFn = [valOrFn];
  }

  $this.data('select2').dataAdapter.query({}, () => {});

  let allOptions = [];
  $this.find('option').each(function() {
    let opt = $(this).data('data');
    allOptions.push(opt);
  });

  let newDataOrder = valOrFn.map(v => {
    let opt = allOptions.find(opt => opt.id == v);
    if (!opt) return null;
    return opt._resultId;
  }).filter(v => !!v);

  $this.data('ordered-data', newDataOrder);
  return $fnVal.apply(this, [valOrFn]);
};

$.fn.setSelect2Options = function(options) {
  this.each(function() {
    var select2 = $(this).data('select2');
    if (!select2) {
      $(this).data('select2-options', options);
    } else {
      _.assign(select2.options.options, options);
      $(this).select2(select2.options.options);
    }
  });
};

$.fn.destroySelect2 = function destroySelect2() {
  if (this.prop('tagName') === 'SELECT') {
    try {
      var sel2 = this.data('select2');
      if (sel2) {
        this.select2('destroy');
      }
    } catch(err) { }
    return this;
  } else {
    this.find('.select2').each(function() {
      $(this).destroySelect2();
    });
    return this;
  }
};

$.fn.enableSelect2 = function enableSelect2(initialData) {
  if (this.prop('tagName') === 'SELECT') {
    if (this.closest('.template').length) return;

    var self = this;
    var options = {dropdownAutoWidth: true};
    if (this.hasAttr('placeholder')) {
      options.placeholder = this.attr('placeholder');
      options.allowClear = true;
    }

    if (initialData && Array.isArray(initialData)) {
      options.data = initialData;
    }

    if (this.hasClass('no-clear')) {
      options.allowClear = false;
    }

    if (this.hasClass('no-search')) {
      options.minimumResultsForSearch = Infinity;
    }

    var modal = this.closest('.modal');
    if (modal.length) {
      options.dropdownParent = modal;
    }

    if (this.hasClass('categoryHeirarchy')) {
      options.templateResult = formatCategoryDropdown;
      options.templateSelection = formatCategoryDisplay;
      options.matcher = categoryMatcher;
    }

    if (this.hasClass('vinfields')) {
      options.ajax = {
        url: function() {
          var field_id = $(this).closest('form.input-form').data('field_id');
          return '/categories/vin-fields/' + field_id;
        },
        processResults: function(data) {
          var usedFields = this.$element.data('used-fields');
          if (!usedFields || !usedFields.length)
            return data;

          return {
            results: _.filter(data.results, function(fld) {
              return usedFields.indexOf(fld.id) < 0;
            })
          };
        }
      };
    }

    if (this.hasClass('consignorSearch')) {
      var auctionID = this.hasAttr('data-auction_id') ? this.data('auction_id') : undefined;
      var includeUnconfirmed = this.hasAttr('data-include_unconfirmed');
      options.templateResult = formatConsignorDropdown;
      options.templateSelection = formatConsignorDropdown;
      options.ajax = {
        url: '/users/sellers-select',
        data: function(params) {
          return {
            auction_id: auctionID || undefined,
            unconfirmed: includeUnconfirmed,
            search: params.term,
            page: params.page || 1
          };
        }
      };
    }

    if (this.hasClass('buyerSearch')) {
      var auctionID = this.hasAttr('data-auction_id') ? this.data('auction_id') : undefined;
      var includeUnconfirmed = this.hasAttr('data-include_unconfirmed');
      options.ajax = {
        url: '/users/buyers-select',
        data: function(params) {
          return {
            auction_id: auctionID || undefined,
            unconfirmed: includeUnconfirmed,
            search: params.term,
            page: params.page || 1
          };
        }
      };
    }

    if (this.hasClass('completedAuctions')) {
      options.ajax = {
        url: '/auctions/completed-search',
        data: function(params) {
          return {
            search: params.term,
            page: params.page || 1
          };
        }
      };
    }

    if (this.hasClass('completedAndArchivedAuctions')) {
      options.ajax = {
        url: '/auctions/completed-and-archived-search',
        data: function(params) {
          return {
            search: params.term,
            page: params.page || 1
          };
        }
      };
    }

    if (this.hasClass('upcomingAuctions')) {
      options.ajax = {
        url: '/auctions/upcoming-search',
        data: function(params) {
          return {
            search: params.term,
            page: params.page || 1
          };
        }
      };
    }

    if (this.hasClass('activeAuctions')) {
      options.ajax = {
        url: '/auctions/active-search',
        data: function(params) {
          return {
            search: params.term,
            page: params.page || 1
          };
        }
      };
    }

    if (this.hasClass('snd-format')) {
      options.templateSelection = function(data) {
        var e = $.Event('snd:formatSelection');
        e.params = data;
        self.trigger(e);
        if (e.isDefaultPrevented() || e.result)
          return e.result;
        return data.text;
      };

      options.templateResult = function(data) {
        var e = $.Event('snd:formatOption');
        e.params = data;
        self.trigger(e);
        if (e.isDefaultPrevented() || e.result)
          return e.result;
        return data.text;
      };
    }

    if (this.hasAttr('data-ajaxurl')) {
      options.templateSelection = function(data) {
        var e = $.Event('snd:formatSelection');
        e.params = data;
        self.trigger(e);
        if (e.isDefaultPrevented() || e.result)
          return e.result;
        return data.text;
      };

      options.templateResult = function(data) {
        var e = $.Event('snd:formatOption');
        e.params = data;
        self.trigger(e);
        if (e.isDefaultPrevented() || e.result)
          return e.result;
        return data.text;
      };

      options.ajax = {
        url: this.data('ajaxurl'),
        dataType: 'json',
        delay: 250,
        data: function(params) {
          // send request to server
          var e = $.Event('snd:formatRequest');
          e.params = params;
          self.trigger(e);
          if (e.isDefaultPrevented() || e.result)
            return e.result;
        },
        processResults: function(data, params) {
          // process response from server
          var e = $.Event('snd:formatResponse');
          e.params = data;
          self.trigger(e);
          if (e.isDefaultPrevented() || e.result)
            return {results: e.result};
          return {results: data};
        }
      };
    }

    if (this.hasClass('invoices')) {
      options.ajax = {
        url: '/invoices/select-search',
        data: function(params) {
          return {
            auction_id: $(this).data('auction_id'),
            search: params.term,
            page: params.page || 1
          };
        }
      };
    }

    if (this.hasClass('statements')) {
      options.ajax = {
        url: '/statements/select-search',
        data: function(params) {
          return {
            auction_id: $(this).data('auction_id'),
            search: params.term,
            page: params.page || 1
          };
        }
      };
    }

    if (this.hasClass('bool-search')) {
      options.placeholder = ' ';
      options.allowClear = true;
      options.templateResult = formatCheckDropdown;
      options.templateSelection = formatCheckDropdown;
      options.minimumResultsForSearch = Infinity;

      if (!this.find('option').length) {
        this.append('<option />');
        this.append('<option value="0" />');
        this.append('<option value="1" />');
      }
    }

    if (this.hasAttr('data-data')) {
      var cboSelf = this;
      options.ajax = {
        transport: function(params, success, failure) {
          var data = cboSelf.data('data');
          var prms = null;
          if (Array.isArray(data)) {
            prms = Promise.resolve({results: data});
          } else {
            prms = Promise.resolve({results: []});
          }
          prms.then(success);
          prms.catch(failure);
        }
      };
    }

    if (this.hasAttr('data-tags')) {
      options.tags = true;
      options.createTag = function(params) {
        var e = $.Event('select2:createTag');
        e.params = params;
        self.trigger(e);
        if (e.isDefaultPrevented() || e.result)
          return e.result;

        var term = $.trim(params.term);
        if (term === '') return null;
        return {
          id: term,
          text: term,
          newTag: true
        };
      };
      if (this.hasAttr('data-tokens')) {
        options.tokenSeparators = this.data('tokens').split('');
      }
    }

    if (this.hasAttr('data-preserve-tag-order')) {
      this.on('select2:selecting', function(e) {
        // this - select box
        let data = $(this).data('ordered-data');
        if (!data) data = [];
        data.push(e.params.args.data._resultId);
        $(this).data('ordered-data', data);
      });

      this.on('select2:unselecting', function(e) {
        let data = $(this).data('ordered-data');
        if (!data) data = [];

        let idx = data.indexOf(e.params.args.data._resultId);
        if (idx >= 0) {
          data.splice(idx, 1);
        }
        $(this).data('ordered-data', data);
      });
    }

    if (this.data('select2-options')) {
      _.assign(options, this.data('select2-options'));
    }

    if (this.hasAttr('multiple') && this.hasAttr('data-display_value') && !options.templateSelection) {
      var attrToDisplay = this.attr('data-display_value');
      if (attrToDisplay == '1') attrToDisplay = 'value';
      options.templateSelection = function(option) {
        if (!option.id || !option.element) return option.text;
        return $(option.element).attr(attrToDisplay);
      };
    }

    if (this.hasClass('credit-card-images')) {
      options.templateSelection = function(option) {
        if (!option.id || !option.element) return option.text;
        var cardTypeIcon = $(option.element).data('card_type');
        if (!cardTypeIcon) return option.text;
        return $('<span class="select2-card-display"><i class="' + cardTypeIcon + '" />' + option.text + '</span>');
      };
      options.templateResult = options.templateSelection;
    }

    this.select2(options);
    let instance = this.data('select2');
    if (this.hasAttr('data-preserve-tag-order')) {
      instance.on('selection:update', function(e) {
        let data = this.$element.data('ordered-data');
        if (!data) data = [];

        let orderedData = data.map(id => e.data.find(d => d._resultId == id));
        this.selection.update(orderedData);
      });

      if (this.hasAttr('data-value')) {
        // hopefully is an array of just values...
        let dataVal = this.data('value');
        if (dataVal && Array.isArray(dataVal)) {
          setTimeout(function() {
            self.val(dataVal).change();
          }, 5);
        }
      }
    }

    /* ALL CAPS for input box */
    if (!this.hasClass('normalcase')) {
      if (this.hasAttr('multiple') && options.tags) {
        var $inputContainer = this.next();
        $inputContainer.on('focus', 'input.select2-search__field', function() {
          $(this).applyUpperCase();
        });
        $inputContainer.on('blur', 'input.select2-search__field', function() {
          $(this).applyUpperCase('destroy');
        });
      } else {
        this.on('select2:open', function() {
          var $input = $(this).data('select2').$dropdown.find('input.select2-search__field');
          $(this).data('select2').$dropdown.addClass('force-uppercase');
          $input.applyUpperCase();
          $input.data('select2', $(this).data('select2'));
        });
        this.on('select2:close', function() {
          var $input = $(this).data('select2').$dropdown.find('input.select2-search__field');
          $input.applyUpperCase('destroy');
        });
      }
    }
  } else {
    this.find('select.select2').each(function() {
      $(this).enableSelect2(initialData);
    });
  }
};

$(function() {

  $(document).on('select2:open', '.select2-hidden-accessible', function() {
    var zIndex = 2050 + (10 * $('.modal:visible').length);
    setTimeout(function() {
      $('.select2-dropdown')
        .css('z-index', zIndex + 1);
    }, 0);
  });

  $(document).on('change', 'select.select2', function() {
    //&.hasError + .select2-container {
    //$(".form-group .select2-selection__rendered").removeAttr('title');
    $('.hasError + .select2-container .select2-selection__rendered, .has-error .select2-selection__rendered').each(function() {
      $(this).data('snd:tmp-err-title', $(this).attr('title'));
      $(this).removeAttr('title');
    });
    $('select.select2:not(.hasError)  + .select2-container .select2-selection__rendered').each(function() {
      var tmpTitle = $(this).data('snd:tmp-err-title');
      if (tmpTitle && !$(this).closest('.has-error').length) {
        $(this).data('snd:tmp-err-title', null);
        $(this).attr('title', tmpTitle);
      }
    });
  });

  $(document).on('select2:close', '.select2', function() {
    $(this).data('select2').$element.focus();
  });

  $(document).on('keypress', '.select2-container--focus', function(e) {
    if ($(this).hasClass('select2-container--open')) return;
    let $element = $(this).data('element');
    let select2 = $element.data('select2');
    let minResults = select2.options.options.minimumResultsForSearch;
    if (minResults === Infinity) return;

    let $search = select2.dropdown.$search;
    if (!$search) return;
    
    $element.select2('open');
    let searchVal = String.fromCharCode(e.which);
    if (!$element.hasClass('normalcase'))
      searchVal = searchVal.toLocaleUpperCase();
    $search.val(searchVal).change();
    select2.dropdown.trigger('query', {term: $search.val()});
  });

  $(document).on('keydown', 'input.select2-search__field', function(e) {
    if (e.which == 9) {
      let select2 = $(this).data('select2');
      let $results = select2.results.getHighlightedResults();
      if ($results.length < 1) {
        return;
      }

      let data = $results.data('data');
      if ((data.element != null && data.element.selected) || (data.element == null && data.selected)) {
        // do not trigger select if already selected
        return;
      }
      select2.results.trigger('select', {data: data});
    }
  });


  // $(document).on('focus', '.select2', function() {
  //   console.log('Select2:focus', this);
  // });

  // $(document).on('blur', '.select2', function() {
  //   console.log('select2:lost', this);
  // });

  $('body').enableSelect2();
});