'use strict';

var $ = window.jQuery;

$.fn.deleteRow = function(allowRestore) {
  var row = this;

  return new Promise(function(resolve) {
    row.find('td, th')
      .each(function() {
        var padding = $(this).css('padding');
        $(this)
          .wrapInner('<div style="display: block; padding: ' + padding + ';" />')
          .data('padding', padding);
      })
      .addClass('deleting-row')
      .css('padding', '0px')
      .children('div')
      .slideUp(700, function() {
        if (!allowRestore) row.remove();
        else row.addClass('hidden');
        resolve(true);
      });
  });
};

$.fn.hideRow = function() {
  if (!this.is(':visible')) return;
  
  var row = this;
  this.find('td, th').each(function() {
    var padding = $(this).css('padding');
    $(this)
      .wrapInner('<div style="display: block; padding: ' + padding + ';" />')
      .data('padding', padding);
  })
  .css('padding', '0px')
  .find('div')
  .slideUp(700, function() {
    row.css('display', 'none');
  });
};

$.fn.showRow = function() {
  var row = this;
  var $divs = row.find('td > div, th > div');
  row.removeClass('hidden').css('display', 'table-row');
  var totalCount = $divs.length;
  var countCompleted = 0;
  return new Promise(function(resolve) {
    $divs.slideDown(700, function() {
      countCompleted++;
      if (countCompleted === totalCount)
        resolve();
    });
  })
  .then(function() {
    var divs = $divs.get();
    $.each(divs, function(index, div) {
      var html = $(div).html();
      var $parent = $(div).parent();
      $parent.css('padding', '').html(html);
    });
    row.find('td, th').removeClass('deleting-row');
  });
};