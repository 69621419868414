/* global $ */
'use strict';

function onFocus(e) {
  let $this = $(this);
  if (!$this.data('select-on-focus')) {
    try {
      $this.selectionStart = 0;
      $this.selectionEnd = $this.value.length + 1;
    }
    catch (err) {
      $this.select();
    }
    $this.data('select-on-focus', true);
  }
}

function onBlur(e) {
  $(this).data('select-on-focus', false);
}

$.fn.selectOnFocus = function selectOnFocus(disable = false) {
  if (disable) {
    this.off('focus', onFocus);
    this.off('blur', onBlur);
  } else {
    this.on('focus', onFocus);
    this.on('blur', onBlur);
  }
  return this;
};