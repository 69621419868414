'use strict';

var $ = window.jQuery;

$.fn.hasAttr = function hasAttr(attr) { return this[0].hasAttribute(attr); };

var inputTextTypes = ['text', 'url'];
$.extend($.expr[':'], {
  alltext: function(elem) {
    return $.inArray(elem.type, inputTextTypes) !== -1;
  }
});

let origClick = $.fn.click;
$.fn.click = function() {
  if (arguments.length < 1) {
    let $fileInputs = this.filter('.hidden-file');
    let $otherElements = this.filter(':not(.hidden-file)');
    $otherElements.trigger('click');
    if ($fileInputs.length) {
      let currentFocus = $(document.activeElement);
      $fileInputs.focus().trigger('click');
      if (currentFocus && currentFocus.length)
        currentFocus.focus();
    }
    return this;
  }
  return origClick.apply(this, arguments);
};

$.fn.isInViewport = function($viewport) {
  var elementTop = $(this).offset().top;
  var elementBottom = elementTop + $(this).outerHeight();

  if (!$viewport) $viewport = $(window);
  var viewportTop = $viewport.scrollTop();
  var viewportBottom = viewportTop + $viewport.height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
};

$.fn.closestWithin = function(selector, count) {
  var parents = [], found = $(), $el = this;
  while (!found.length && parents.length < count) {
    var $p = $el.parent();
    found = $el.parent(selector);
    if (!$p.length) break;
    parents.push($p.get(0));
    $el = $p;
  }
  return found;
};

var scriptsLoaded = [];
$.getScriptIf = function(scriptPath) {
  if ($('script[src="' + scriptPath + '"]').length) return Promise.resolve();
  if (scriptsLoaded.indexOf(scriptPath) > -1) return Promise.resolve();
  return new Promise(function(resolve, reject) {
    $.getScript(scriptPath)
    .done(resolve)
    .fail(function() {
      reject(new Error('Script load failed: ', scriptPath));
    });
  });
};

var stylesLoaded = [];
$.getStyleIf = function(stylePath) {
  if ($('link[href="' + stylePath + '"]').length) return;
  if (stylesLoaded.indexOf(stylePath) > -1) return;

  var link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = stylePath;
  document.getElementsByTagName('head')[0].appendChild(link);
};

$.fn.priorityOn = function(events, selector, data, handler) {
  if (!events) return this;
  var eventTypes = events.split(' ');
  if (!eventTypes || !eventTypes.length) return;

  this.each(function() {
    var $this = $(this);
    for (var i = 0; i < eventTypes.length; i++) {
      $this.on(eventTypes[i], selector, data, handler);

      var currentBindings = $._data(this, 'events')[eventTypes[i]];
      if ($.isArray(currentBindings))
        currentBindings.unshift(currentBindings.pop());
    }
  });
  return this;
};

function inputUpperCase(e) {
  var ss = e.target.selectionStart;
  var se = e.target.selectionEnd;
  e.target.value = e.target.value.toLocaleUpperCase();
  e.target.selectionStart = ss;
  e.target.selectionEnd = se;
}

$.fn.applyUpperCase = function(action) {
  this.off('input', inputUpperCase);
  if (action === 'destroy') return this;
  return this.priorityOn('input', inputUpperCase).trigger('input');
};

$.fn.setClass = function(className, isSet) {
  if (isSet) {
    this.addClass(className);
  } else {
    this.removeClass(className);
  }
};

function unselectTargetedRadio(e, $radio) {
  if ($radio.data('radiotype') === 'standard') {
    return;
  }
  if ($radio.is(':checked')) {
    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();
    $radio.prop('checked', false);
    var $btn = $radio.parent('.btn.active');
    if ($btn.length) {
      $btn.removeClass('active');
    }
  }
}

$(function() {
  $(document).on('click', '.disabled, [disabled]', function() {
    if ($(this).hasClass('dropzone'))
      return;
    return false;
  });
  
  $(document).on('click', 'label', function(e) {
    var $label = $(e.target);
    var $radio = $label.find('input[type=radio]');
    if (!$radio.length && $label.attr('for')) {
      $radio = $('#' + $label.attr('for'));
    }
    if (!$radio.length) return;
    unselectTargetedRadio(e, $radio);
  })
  .on('click', 'input[type=radio]', function(e) {
    var $radio = $(e.target);
    unselectTargetedRadio(e, $radio);
  });
});