'use strict';

var $ = window.jQuery;

let $input = null;

$.formatPhone = function(number) {
  if (!$input || !window.intlTelInputUtils) return number;

  let tel = $input.data('plugin_intlTelInput');
  
  $input.intlTelInput('setNumber', number);
  let country = tel.getSelectedCountryData();

  $input.intlTelInput('setNumber', '');
  return window.intlTelInputUtils.formatNumber(
    number,
    'ZZ', // parse as international
    country.dialCode == 1
      ? 2 // National for US Dial Code
      : 1 // International for other countries
  );
};

$.ensurePhoneLib = function() {
  var gotScript = null;
  if (!window.telScript) {
    gotScript = window.telScript = $.getScriptIf('/bower_components/intl-tel-input/build/js/intlTelInput.min.js')
    .then(function() {
      $('<link href="/bower_components/intl-tel-input/build/css/intlTelInput.css" rel="stylesheet">').appendTo('head');
    });
  } else {
    gotScript = Promise.resolve(true);
  }
  return gotScript;
};

$.initPhoneLib = function() {
  let input = document.createElement('input');
  input.setAttribute('type', 'tel');

  // let container = document.createElement('div');
  // container.appendChild(input);
  $.ensurePhoneLib().then(function() {
    $(input).intlTelInput({
      utilsScript: '/bower_components/intl-tel-input/build/js/utils.js'
    });

    $input = $(input);
  });
};

$.fn.applyPhoneUI = function() {
  var self = this;

  $.ensurePhoneLib().then(function() {
    self.intlTelInput({ utilsScript: '/bower_components/intl-tel-input/build/js/utils.js' })
    .done(function() {
      self.each(function() {
        var o = $(this),
            id = o.attr('id');
        
        var $input = $('[name=' + id + ']');
        if (!$input.length)
          $input = $('#' + id + '_hidden');

        var v = $input.val();

        if (v) o.intlTelInput('setNumber', v);
      })
      .on('blur', function() {
        var o = $(this),
            id = o.attr('id'),
            v = o.intlTelInput('getNumber'),
            e = o.intlTelInput('getExtension'); 
        
        var $formInput = $('[name=' + id + ']');
        if (!$formInput.length)
          $formInput = $('#' + id + '_hidden');
        $formInput.val(v);


        if (e) {
          var $extInput = $('[name=' + id + '_ext]');
          if (!$extInput.length)
            $extInput = $('#' + id + '_ext');
          $extInput.val(e);
          if (!$extInput.length) {
            $formInput.val(v + ' x' + e);
            v += ' ext. ' + e;
          }
        }
        o.intlTelInput('setNumber', v);
      });
    });
  });
  return self;
};