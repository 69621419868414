/* globals $, toastr */
'use strict';

function clickFieldOverride(e) {
  e.preventDefault();

  let override = $(this).data('override');
  if (typeof override !== 'object' || !override.action || !override.label || !override.field || !override.url)
    return;

  $.modal('/override/modal-field', {
    method: 'POST',
    data: JSON.stringify({
      action: override.action,
      entity: '',
      objectId: '',
      message: '',
      prompt: '',
      field: override
    }),
    contentType: 'application/json',
    shown: function($modal) {
      $modal.on('override:field-validate', function(e) {
        return new Promise(function(resolve, reject) {
          $.ajax({
            method: 'POST',
            url: override.url + '/override-field/validate',
            data: JSON.stringify({
              field: override,
              data: e.params.data
            }),
            contentType: 'application/json'
          })
          .done(resolve)
          .fail($.handleFormErrors(e.params.form, reject));
        });
      });
    }
  })
  .then(function(result) {
    if (!result || !result.hasOwnProperty('field') || !result.field.hasOwnProperty('new_value'))
      return;
    
    // build the ajax for CHANGING the field
    $.ajax({
      method: 'POST',
      url: override.url + '/override-field',
      data: JSON.stringify({
        field: override,
        override: result,
        data: {new_value: result.field.new_value}
      }),
      contentType: 'application/json'
    })
    .done((saveResult) => {
      if (saveResult) {
        if (saveResult.errors && saveResult.errors.length) {
          $.handleSyncErrors(saveResult.errors);
        }
        if (saveResult.wasSynced) {
          toastr.success('Synced');
        }
      }
      // update original field if possible
      if (result.field.selector) {
        $(result.field.selector).html(result.field.new_value);
      } else {
        $('.form-control-static#' + result.field.field).html(result.field.new_value);
      }
      toastr.success(result.field.label + ' has been updated.');
    })
    .fail($.handleFormErrors());
  });
}

$.fn.enableFieldOverride = function() {
  this.each(function() {
    let override = $(this).data('override');

    /*
    action
    value
    label
    field
    url
    */
    if (typeof override !== 'object' || !override.action || !override.label || !override.field || !override.url)
      return;

    $(this).click(clickFieldOverride);
  });
};

$(function() {
  $('[data-override]').enableFieldOverride();
});