/* global $ */

'use strict';

let $globalRequest = null;

const SPLASH_LOGO_URL = '/images/topaz/jma-logo-bw.png';
const SIGN_IMG_URL = '/images/topaz/jma-sign-bw.png';

const compareVersion = require('compare-versions');
const toastr = window.toastr;

const TOPAZ_PROXY = $({});

const TOPAZ_FILTERS = [{
  vendorId: 0x06a8,
  productId: 0x0043,
}];

let TopazDevice = null;

const TOPAZ_SIZE = { width: 240, height: 64 };
window.TOPAZ_SIZE = TOPAZ_SIZE;

class Topaz {
  size = TOPAZ_SIZE;
  extents = { x: 500, y: 450, width: 1750, height: 500 };
  timeouts = { command: 6000, poll: 100 };
  commands = [];
  penUpTimeout = null;
  lastPenReportTime = null;
  last = null;
  response = null;
  capturing = false;
  drawing = false;
  spot = null;
  proxy = $({});

  CAPTURE_MODES = {
    none: 0,
    default: 1,
    ink: 2,
    inkInvert: 3
  };

  COMMANDS = {
    default: 4, // 0x04
    ink: 20, // 0x14
    inkInvert: 9, // 0x09
    refresh: 18, // 0x12
    quiet: 17, // 0x11
    writeDisplay: 7, // 0x07
    writeDisplayZ: 26, // 0x1a
    writeHidden: 23, // 0x17
    keyPad: 11 // 0x0b
  };

  IMAGE_MAP = [128, 64, 32, 16, 8, 4, 2, 1];

  constructor(options) {
    this.options = options;

    this.$canvas = $('<canvas style="visibility: hidden;" />')
      .attr('width', this.size.width)
      .attr('height', this.size.height)
      .css('width', this.size.width)
      .css('height', this.size.height);

    this.canvas = this.$canvas[0].getContext('2d');
    this.canvas.width = this.size.width;
    this.canvas.height = this.size.height;
    this.canvas.imageSmoothingEnabled = false;
    this.canvas.fillStyle = '#fff';
    this.canvas.strokeStyle = '#000';
    this.canvas.fillRect(0, 0, this.size.width, this.size.height);

    if (options.ui && options.ui.container) {
      this.$element = $(options.ui.container);

      this.$container = $('<div class="topaz-container"/>').appendTo(this.$element);
      this.$message = $('<div class="topaz-message" style="display: none;"/>').appendTo(this.$container);

      this.$request = $('<button type="button" style="display: none;">Request Access</button>')
        .appendTo(this.$container)
        .on('click', this.request.bind(this));

      this.$canvas
        .css('width', options.ui.width)
        .css('height', options.ui.height)
        .appendTo(this.$element);
    }

    this.clip = { x: 0, y: 0, width: this.size.width, height: this.size.height };

    if (this.options.sign && this.options.sign.clip) this.clip = this.options.sign.clip;

    setTimeout(async () => {
      await this.open();
    }, 1);
  }

  on(type, handler) {
    this.proxy.on(type, handler);
  }

  async open() {
    try {
      if (!navigator.userAgent.indexOf('Chrome')) throw new Error('The device can only be accessed on Google Chrome.');
      if (!navigator.hid) throw new Error('The device can not be accessed. Go to "chrome://flags" and enable "Experimental Web Platform features"');

      const devices = await navigator.hid.getDevices({ filters: TOPAZ_FILTERS });
      if (!devices.length) throw new Error('The device can not be found.');
      this.device = devices[0];

      await this._init();
    } catch (err) {
      this._error(err);
    }
  }

  async request() {
    try {
      sessionStorage.setItem('topaz_splash', JSON.stringify(false));
      this.device = await navigator.hid.requestDevice({ filters: TOPAZ_FILTERS });
      await this._init();
    } catch (err) {
      if (err.message === 'No device selected.') return;
      this._error(err);
    }
  }

  capture() {
    this._clearCommandBuffer();

    let spot = { x: 0, y: 0, width: this.size.width, height: this.size.height };

    this.canvas.fillRect(0, 0, this.size.width, this.size.height);

    if (this.options.sign) {
      this._sendCommand(this.COMMANDS.writeHidden, 2, 0, 0, this.size.width, this.size.height, this.options.sign.data);
      this._sendCommand(this.COMMANDS.refresh, 2, 0, 0, this.size.width, this.size.height);
    } else {
      this._sendCommand(this.COMMANDS.refresh, 0, 0, 0, this.size.width, this.size.height);
    }

    this._sendCommand(this.COMMANDS.keyPad, 2, this.clip.x, this.clip.y, this.clip.width, this.clip.height);
    this._sendCommand(this.COMMANDS.ink);

    sessionStorage.setItem('topaz_splash', JSON.stringify(false));
    this.capturing = true;
    this.proxy.triggerHandler('capture');
  }

  save() {
    const data = this.$canvas[0].toDataURL('image/png');

    this.canvas.fillRect(0, 0, this.size.width, this.size.height);
    this.proxy.triggerHandler('signature', [data]);
    this.reset(!!this.options.splash);

    return data;
  }

  reset(splash) {
    this.canvas.fillRect(0, 0, this.size.width, this.size.height);

    let isOnSplashScreen = sessionStorage.getItem('topaz_splash');
    if (isOnSplashScreen) isOnSplashScreen = JSON.parse(isOnSplashScreen);

    if (!isOnSplashScreen) {
      this._clearCommandBuffer();
      this._sendCommand(this.COMMANDS.refresh, this.CAPTURE_MODES.none, 0, 0, this.size.width, this.size.height);

      if (this.options.splash && splash !== false) {
        this._sendCommand(this.COMMANDS.writeHidden, 2, 0, 0, this.size.width, this.size.height, this.options.splash.data);
        this._sendCommand(this.COMMANDS.refresh, 2, 0, 0, this.size.width, this.size.height);
        sessionStorage.setItem('topaz_splash', JSON.stringify(true));
      }
    }

    this.proxy.triggerHandler('reset');
  }

  _error(err) {
    this.$canvas.css('visibility', 'hidden');
    if (this.$element) {
      this.$request.css('display', 'block');
      this.$message.css('display', 'block').text(err.message);
    }

    this.proxy.triggerHandler('error', [err]);
  }

  async _init() {
    try {
      if (this.$element) {
        this.$canvas.css('visibility', 'visible');
        this.$request.css('display', 'none');
        this.$message.css('display', 'none');
      }

      await this.device.open();
      this.device.addEventListener('inputreport', this._onData.bind(this));

      if (this.options.sign) {
        this.options.sign.data = await this._loadImage(this.options.sign.url);
      }
      if (this.options.splash) {
        this.options.splash.data = await this._loadImage(this.options.splash.url);
      }

      this._processCommands();
      if (!this.options.autocapture) {
        this.reset(this.options.autocapture !== true);
      }
      if (this.options.autocapture === true) {
        this.capture();
      }

      this.proxy.triggerHandler('init');
    } catch (err) {
      this._error(err);
    }
  }

  _loadImage(url) {
    return new Promise(resolve => {
      const img = new Image();
      const w = this.canvas.width;
      const h = this.canvas.height;
      const bw = Math.floor((w - 1) / 8) + 1;
      const buffer = new Uint8Array(bw * h);

      img.onload = () => {
        let data;

        this.$canvas.css('visibility', 'hidden');
        this.canvas.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.canvas.drawImage(img, 0, 0, img.width, img.height, 0, 0, this.canvas.width, this.canvas.height);
        data = this.canvas.getImageData(0, 0, this.canvas.width, this.canvas.height).data;
        this.canvas.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.$canvas.css('visibility', 'visible');

        for (let y = 0; y < h; y++) {
          for (let x = 0; x < w; x++) {
            const o = y * w * 4 + x * 4;
            const r = data[o];
            const g = data[o + 1];
            const b = data[o + 2];

            const avg = Math.floor((r + g + b) / 3);
            if (avg < 128) {
              const i = Math.floor(x / 8) + y * bw;
              buffer[i] |= this.IMAGE_MAP[x % 8];
            }
          }
        }

        resolve(buffer);
      };

      img.src = url;
    });
  }

  async _processCommands() {
    if (this.commands.length) await this._sendData();
    setTimeout(this._processCommands.bind(this), 200);
  }

  _sendCommand(cmd, mode, x, y, w, h, extra) {
    let data = [];
    let csum = 0;

    data.push(cmd);

    if (mode !== undefined) data.push(mode);
    if (x !== undefined) {
      data.push(x >> 8);
      data.push(x & 255);
    }
    if (y !== undefined) {
      data.push(y >> 8);
      data.push(y & 255);
    }
    if (w !== undefined) {
      data.push(w >> 8);
      data.push(w & 255);
    }
    if (h !== undefined) {
      data.push(h >> 8);
      data.push(h & 255);
    }
    if (extra !== undefined) data.push.apply(data, extra);

    for (let i = 0; i < data.length; i++) {
      csum = (csum + data[i]) & 255;
    }

    this.commands.push({ data, csum });
  }

  _clearCommandBuffer() {
    this.commands = [];
  }

  async _sendData() {
    const cmd = this.commands.shift();

    if (!cmd) return;

    await this.device.sendReport(0, new Uint8Array([1, this.COMMANDS.quiet]));
    await this._sleep(10);

    await this.device.sendReport(0, new Uint8Array([1, this.COMMANDS.quiet]));
    await this._sleep(10);

    for (let i = 0; i < cmd.data.length; i += 6) {
      var buffer = cmd.data.slice(i, i + 6);
      buffer.unshift(buffer.length);
      await this.device.sendReport(0, new Uint8Array(buffer));
    }

    await this._waitForResponse(cmd.csum);

    if (this.commands.length) await this._sendData();
  }

  _waitForResponse(csum) {
    if (csum === -1) return true;

    let start = Date.now();

    return new Promise((resolve, reject) => {
      const wait = () => {
        if (Date.now() > start + this.timeouts.command) {
          this.response = null;

          resolve(false);
          return;
        }

        if (this.response !== null) {
          const result = this.response === csum;
          this.response = null;
          resolve(result);
          return;
        }

        setTimeout(wait, this.timeouts.poll);
      };

      this.response = null;
      wait();
    });
  }

  _sleep(ms) {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  }

  _hotspot(spot, state) {
    const curr = this.spot;

    if (!spot && curr) {
      /**
       * Turns off HOTSPOT, if leave hotspot (pen down, but dragged out)
       */
      clearInterval(this.penUpTimeout);
      setTimeout(() => {
        this._sendCommand(this.COMMANDS.refresh, 2, curr.x, curr.y, curr.width, curr.height);
        this._sendCommand(this.COMMANDS.ink);
      }, 100);

      this.spot = null;
      return;
    }

    // current spot, already down - ignore
    if (spot && this.spot === spot && state === 1)
      return;

    if (spot) {
      // Have SPOT
      this.spot = state === 1 ? spot : null;
      if (state === 1) {
        // penUpTimeout
        // PEN down
        this._sendCommand(this.COMMANDS.refresh, 1, spot.x, spot.y, spot.width, spot.height);
        this._sendCommand(this.COMMANDS.ink);
        this.penUpTimeout = setInterval(() => {
          let currentTime = (new Date()).getTime();
          if (currentTime > (this.lastPenReportTime + 250)) {
            clearInterval(this.penUpTimeout);
            this._hotspot(spot, 0);
          }
        }, 10);
      } else {
        clearInterval(this.penUpTimeout);
        // PEN up
        switch (spot.name) {
          case 'clear':
            this.capture();
            break;
          case 'ok':
            this.save();
            break;
        }

        this.proxy.triggerHandler('hotspot', [spot.name]);
      }
    }
  }

  _onData(evt) {
    const data = new Uint8Array(evt.data.buffer);
    const len = data[0];
    if (len === 1) {
      this.response = data[1];
    }

    if (this.capturing && len === 5) {
      this.lastPenReportTime = (new Date()).getTime();
      const status = data[1] & 1;
      const x = Math.floor(((((data[3] & 31) << 7) + (data[2] & 255) - this.extents.x) / this.extents.width) * this.size.width);
      const y = Math.floor(((((data[5] & 31) << 7) + (data[4] & 255) - this.extents.y) / this.extents.height) * this.size.height);

      if (status === 1) {
        if (!this.spot && !this.drawing && x >= this.clip.x && x < this.clip.x + this.clip.width && y >= this.clip.y && y < this.clip.y + this.clip.height) this.drawing = true;

        if (this.drawing) {
          if (this.last) {
            this.canvas.beginPath();
            this.canvas.moveTo(this.last.x, this.last.y);
            this.canvas.lineTo(x, y);
            this.canvas.stroke();
            this.proxy.triggerHandler('drawing', [this.$canvas]);
            this.drawing = true;
          }

          this.last = { x, y };
        } else {
          this.last = null;

          if (this.options.sign && this.options.sign.hotspots && this.options.sign.hotspots.length) {
            const spot = this.options.sign.hotspots.find(spot => x >= spot.x && x < spot.x + spot.width && y >= spot.y && y < spot.y + spot.height);
            this._hotspot(spot, 1);
          }
        }
      } else {
        if (this.spot) this._hotspot(this.spot, 0);
        if (this.last) {
          this.last = null;
          this.drawing = false;
        }
      }
    }
  }
}

class TopazSig {

  /**
   * Checks to see if a device is already available
   *  and if the feature is allowed.
   * 
   * @returns {boolean|string} Boolean indicates a hard yes/no. String means a fixable error could allow it (contains html).
   */
  static async CanRequestDevice() {
    if (!navigator.userAgent.match(/Chrome/)) return 'To connect and use a Topaz signature device please use Google Chrome.';
    
    if (!navigator.hid) {
      let versionMatch = navigator.userAgent.match(/Chrome\/((?:\.?\d+){1,4})/);
      if (!versionMatch) return 'To connect and use a Topaz signature device you must be using Chrome 78+ with the "Experimental Web Platform features" flag enabled.';

      let flagCompare = compareVersion(versionMatch[1], '78');
      if (flagCompare < 0) return `To connect and use a Topaz signature device you must be using at least Chrome version 78 (you are using ${versionMatch[1]}).`;
      return 'To connect and use a Topaz signature device you need to enable the feature in Chrome. Go to "chrome://flags" and enable "Experimental Web Platform features".  This will require reloading Chrome (you should be prompted).';
    }

    let devices = await navigator.hid.getDevices({filters: TOPAZ_FILTERS});
    if (devices.length) return false; // already have device access
    return true;
  }

  static async request(options = {}) {
    try {
      sessionStorage.setItem('topaz_splash', JSON.stringify(false));

      await navigator.hid.requestDevice({ filters: TOPAZ_FILTERS });
      if ($globalRequest)
        $globalRequest.addClass('hidden');

      TopazSig.connect(options);
    }
    catch (err) {
      if (err.message === 'No device selected.') return;
      toastr.error(err.message);
    }
  }

  static on(type, handler) {
    TOPAZ_PROXY.on(type, handler);
  }

  static off(type, handler) {
    TOPAZ_PROXY.off(type, handler);
  }

  static save() {
    if (!TopazDevice) throw new Error('Device not yet connected');
    TopazDevice.save();
  }

  static capture() {
    if (!TopazDevice) throw new Error('Device not yet connected');
    TopazDevice.capture();
  }

  static reset(splash) {
    if (!TopazDevice) throw new Error('Device not yet connected');
    TopazDevice.reset(splash);
  }

  static async connect(options = {}) {
    if (TopazDevice) throw new Error('Device is already connected');
    if (!navigator.hid) throw new Error('The device can not be accessed. Go to "chrome://flags" and enable "Experimental Web Platform features"');

    let devices = await navigator.hid.getDevices({filters: TOPAZ_FILTERS});
    if (!devices.length) {
      return;
    }

    TopazDevice = new Topaz({
      autocapture: !!options.autocapture,

      sign: {
        url: SIGN_IMG_URL,
        clip: { x: 0, y: 20, width: 240, height: 44 },
        hotspots: [
          { name: 'ok', x: 192, y: 0, width: 48, height: 20 },
          { name: 'clear', x: 0, y: 0, width: 48, height: 20 }
        ]
      },

      splash: {
        url: SPLASH_LOGO_URL
      }
    });

    TopazDevice.on('drawing', (e, $canvas) => TOPAZ_PROXY.trigger('drawing', [$canvas]));
    TopazDevice.on('reset', (e) => TOPAZ_PROXY.trigger('reset'));
    TopazDevice.on('signature', (e, data) => TOPAZ_PROXY.trigger('signature', [data]));
    TopazDevice.on('capture', () => TOPAZ_PROXY.trigger('capture'));
    TopazDevice.on('error', (e, err) => {
      console.error(err);
      TOPAZ_PROXY.trigger('error', [err]);
    });
    TOPAZ_PROXY.trigger('connected');
  }
}

window.TopazSig = TopazSig;

$(async function() {
  $globalRequest = $('#globalTopazRequest');

  let canRequest = await TopazSig.CanRequestDevice();
  if (canRequest) {
    $globalRequest.removeClass('hidden');

    $globalRequest.on('click', function(e) {
      e.preventDefault();
  
      if (typeof canRequest === 'string') {
        $.msgbox('Topaz Signature Device', canRequest);
        return;
      }
      TopazSig.request();
    });
  } else {
    TopazSig.connect();
  }
});